import React from 'react';
import ModalWrapper from './ModalWrapper';

function TestModal({ data }) {
  return (
    <ModalWrapper size="mini" header="test modal">
      <div>The data is: {data || 1}</div>
    </ModalWrapper>
  );
}

export default TestModal;
