import {
    differenceInDays,
    differenceInHours,
    isAfter,
    isBefore,
    isSameDay,
    isTomorrow,
} from 'date-fns';
import { toast } from 'react-toastify';
import { logEvent } from '../config/analytics';
import {
    assignmentCompletedFirestore,
    assignmentIncompleteFirestore,
} from '../firestore/assignmentService';

export const SAME_DAY_SCORE = 10;
export const PREV_DAY_SCORE = 15;

export function calculateScore(assignment) {
    const { dueDate } = assignment;

    const now = new Date();

    let score, scoreDays, scoreHours;

    //scoreDays is the main controlling factor of the score (exponential)
    //followed by scoreHours

    //when dueDate is in the future
    if (isAfter(dueDate, now)) {
        if (isSameDay(dueDate, now)) {
            scoreDays = SAME_DAY_SCORE;
            scoreHours = differenceInHours(dueDate, now);
        } else if (isTomorrow(dueDate)) {
            scoreDays = PREV_DAY_SCORE;
            scoreHours = differenceInHours(dueDate, now) / 2;
        } else {
            scoreDays = differenceInDays(dueDate, now) * 10;
            scoreHours = differenceInHours(dueDate, now) / 5;
        }

        //calculate scoreHours
        if (isSameDay(dueDate, now) && isBefore(dueDate, now)) {
            scoreHours = differenceInHours(now, dueDate);
        }
    }

    //when dueDate is in the past
    if (isBefore(dueDate, now)) {
        // if (isSameDay(dueDate, now)) {
        //     scoreDays = -SAME_DAY_SCORE;
        //     scoreHours = differenceInHours(dueDate, now);
        // } else if (isYesterday(dueDate)) {
        //     scoreDays = -PREV_DAY_SCORE;
        //     scoreHours = differenceInHours(dueDate, now) / 3
        // } else {
        //     scoreDays = differenceInDays(dueDate, now) * 10;
        //     scoreHours = differenceInHours(dueDate, now) / 5;
        // }

        //returns 0 for past due dates for now because otherwise if a student follows the course in the middle of the semester, he/she will rack up a bunch of negative points, which will be frustrating to the user.
        scoreDays = 0;
        scoreHours = 0;
    }

    score = scoreDays + scoreHours;
    return score.toFixed(0);
}

export async function handleSetCompleted(assignment) {
    const score = calculateScore(assignment);
    if (!assignment.completed) {
        await assignmentCompletedFirestore(score, assignment);
        if (score > 0) {
            logEvent('assignment_completed');
            // toast.success(`Completed! You earned ${score} study points!`);
        } else {
            toast(
                `You earned ${score.toFixed(
                    0
                )} points because the assignment was past due`
            );
        }
    } else {
        await assignmentIncompleteFirestore(assignment);
    }
}
