import React, { Fragment } from 'react';
import ButtonComponent from '../../../app/common/buttons/ButtonComponent';
import { MessageContainer } from '../../../app/components/CourseComponents';
import { Message } from '../../../app/components/Text';
import AssignmentListItem from '../../assignments/AssignmentListItem';
import { destructureMaps, sortDateGroup } from '../../../app/util/dateUtil';
import { AssignmentGroup } from '../../../app/components/AssignmentComponents';
import { DateGroupTitle } from '../../../app/components/Titles';
import { format } from 'date-fns';
import { openModal } from '../../../app/common/modals/modalReducer';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LoadingSpinner } from '../../../app/components/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroller';

const AssignmentsContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 5rem;
`;

export default function CourseDetailedAssignments({
  assignments,
  isAdmin,
  course,
  editMode,
  loading,
  getNextAssignments,
  moreAssignments,
}) {
  const dateGroups = destructureMaps(sortDateGroup(assignments));
  const dispatch = useDispatch();
  return (
    <Fragment>
      {assignments.length !== 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={getNextAssignments}
          hasMore={!loading && moreAssignments}
          initialLoad={false}
          loader={<LoadingSpinner />}
        >
          <AssignmentsContainer>
            {dateGroups.map((dateGroup, i) => (
              <AssignmentGroup
                key={i}
                style={
                  i === dateGroups.length - 1 ? { marginBottom: '8rem' } : null
                }
              >
                <DateGroupTitle>
                  {format(dateGroup[0].dueDate, 'iiii, LLLL do')}
                </DateGroupTitle>
                {dateGroup.map((assignment) => (
                  <AssignmentListItem
                    isAdmin={isAdmin}
                    editMode={editMode}
                    key={assignment.id}
                    assignment={assignment}
                    course={course}
                    hideCourseSection
                  />
                ))}
              </AssignmentGroup>
            ))}
            {/* message for students */}
            {assignments.length < 1 && !isAdmin ? (
              <Message>No assignments in this course</Message>
            ) : null}
            {/* message for admins */}
            {assignments.length < 1 && isAdmin && !editMode ? (
              <MessageContainer>
                <Message>No assignments yet! </Message>
                <ButtonComponent
                  primary
                  color="primary"
                  width="100%"
                  clickAction={() =>
                    dispatch(
                      openModal({
                        modalType: 'AssignmentFormModal',
                        modalProps: {
                          course,
                        },
                      })
                    )
                  }
                >
                  Add One
                </ButtonComponent>
              </MessageContainer>
            ) : null}
          </AssignmentsContainer>
        </InfiniteScroll>
      )}
      {assignments.length < 1 && (
        <Message style={{ marginTop: '2rem' }}>No assignments!</Message>
      )}
    </Fragment>
  );
}
