import {
    ADD_ASSIGNMENT_TO_COURSE,
    CLEAR_COURSE_ASSIGNMENTS,
    CLEAR_SELECTED_COURSE,
    CREATE_COURSE,
    DELETE_COURSE,
    FETCH_COURSE,
    FETCH_COURSES,
    FETCH_COURSE_ASSIGNMENTS,
    UPDATE_COURSE,
} from './courseConstants';

const initialState = {
    courses: [],
    selectedCourse: null,
    selectedCourseAssignments: [],
    moreAssignments: true,
};

export default function courseReducer(state = initialState, { type, payload }) {
    switch (type) {
        case FETCH_COURSES:
            return {
                ...state,
                courses: payload,
            };
        case CREATE_COURSE:
            return {
                ...state,
                courses: [...state.courses, payload],
            };
        case UPDATE_COURSE:
            return {
                ...state,
                courses: [
                    ...state.courses.filter(
                        (course) => course.id !== payload.id
                    ),
                    payload,
                ],
            };
        case DELETE_COURSE:
            return {
                ...state,
                courses: [
                    ...state.courses.filter(
                        (course) => course.id !== payload.id
                    ),
                ],
            };
        case ADD_ASSIGNMENT_TO_COURSE:
            return {
                selectedCourse: payload.courseId,
                selectedCourseAssignments: [
                    ...state.selectedCourseAssignments,
                    payload.assignment,
                ],
            };
        case CLEAR_SELECTED_COURSE:
            return {
                ...state,
                selectedCourse: null,
                selectedCourseAssignments: [],
            };
        case FETCH_COURSE_ASSIGNMENTS:
            return {
                ...state,
                selectedCourseAssignments: [
                    ...state.selectedCourseAssignments,
                    ...payload.assignments,
                ],
                moreAssignments: payload.moreAssignments,
            };
        case FETCH_COURSE:
            return {
                ...state,
                selectedCourse: payload,
            };
        case CLEAR_COURSE_ASSIGNMENTS:
            return {
                ...state,
                selectedCourseAssignments: [],
                moreAssignments: true,
            };
        default:
            return state;
    }
}
