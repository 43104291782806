import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useField, useFormikContext } from 'formik';
import { Fragment } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { InputWrapper, Label } from './LoginTextInput';
import { ErrorMessage } from '../../components/Text';

export default function SingleDateInput({ value, error, id, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [focused, setFocused] = useState(false);
  const [fieldDate, setFieldDate] = useState(value);

  // const isDayBlocked = (momentDate) => {
  //   if (
  //     momentDate.format('ddd') === 'Sat' ||
  //     momentDate.format('ddd') === 'Sun'
  //   )
  //     return true;

  //   return false;
  // };

  return (
    <Fragment>
      <InputWrapper>
        <Label>{props.label}</Label>
        <SingleDatePicker
          date={moment(fieldDate)}
          focused={focused}
          onFocusChange={() => setFocused(!focused)}
          onDateChange={(momentObj) => {
            const date = momentObj.toDate();
            setFieldDate(date);
            setFieldValue(field.name, date);
          }}
          id={id}
          noBorder
          daySize={44}
          anchorDirection="left"
          required
          numberOfMonths={1}
          inputIconPosition="after"
          // isDayBlocked={isDayBlocked}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
}
