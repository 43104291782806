import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../../components/Text';
import { COLORS } from '../../layout/constants';

const Input = styled.input`
  padding: 0.75rem 1rem;
  box-sizing: border-box;
  font-weight: 500;
  flex: 3;
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 2px solid ${(p) => p.theme.colors.border}; */
  border: 1px solid ${(p) => p.theme.colors.tertiaryText};
  border-radius: 8px;
  width: ${(p) => p.width || '100%'};
  /* box-shadow: inset 0px 1px 2px 0 rgba(0, 0, 0, 0.1); */
  /* background: linear-gradient(
    to bottom right,
    ${COLORS.gradientStart},
    ${COLORS.gradientEnd}
  ); */
  background: ${(p) => p.theme.colors.background};

  &:focus,
  &:active {
    outline: none;
    border: 3px solid ${(p) => p.theme.colors.primary};
  }
  &::placeholder {
    color: ${COLORS.secondaryText};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  flex: 1 0 1.25rem;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.primaryText};
  margin-bottom: 0.5rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  width: ${(p) => p.width || '100%'};
  box-sizing: border-box;
`;

export default function LoginTextInput({
  label,
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  name,
  type,
  width,
  style,
}) {
  return (
    <Fragment>
      <InputWrapper style={style} width="width">
        <Label>{label}</Label>
        <Input
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          type={type}
          width={width}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputWrapper>
    </Fragment>
  );
}
