import * as React from 'react';
import { COLORS } from '../../layout/constants';

function SvgUser(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.width || '24px'}
            width={props.width || '24px'}
            viewBox="0 0 20 20"
            fill={props.color}
            {...props}
        >
            <path
                fillRule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clipRule="evenodd"
            />
        </svg>
    );
}

export default SvgUser;
