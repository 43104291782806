import React, { Fragment } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../layout/constants';
import { InputWrapper, Label } from './LoginTextInput';

const Input = styled.textarea`
  border: none;
  padding: 1rem 1.25rem;
  background-color: ${COLORS.background};
  font-weight: 500;
  flex: 3 0 4rem;
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid ${(p) => p.theme.colors.tertiaryText};
  border-radius: 8px;
  width: ${(p) => p.width || '100%'};
  background: ${(p) => p.theme.colors.background};
  font-family: inherit;

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${COLORS.secondaryText};
    font-weight: 400;
    font-size: 0.9rem;
  }
`;

const ErrorLabel = styled.p`
  color: ${(p) => p.theme.colors.error};
`;

export default function TextArea({
  label,
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  name,
}) {
  return (
    <Fragment>
      <InputWrapper>
        <Label>{label}</Label>
        <Input
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          wrap="hard"
        />
      </InputWrapper>
      {error && <ErrorLabel>{error.message}</ErrorLabel>}
    </Fragment>
  );
}
