import styled, { css } from 'styled-components';
import { COLORS } from '../../layout/constants';
import React, { Fragment } from 'react';
import { LoadingButtonSpinner } from '../../components/LoadingButtonSpinner';
import StyledLink from '../../components/StyledLink';
import RightArrow from '../../icons/solid/ArrowRight';
import Plus from '../../icons/solid/Plus';
import Adjustments from '../../icons/solid/Adjustments';
import Remove from '../../icons/solid/X';
import Pencil from '../../icons/solid/Pencil';
import Trash from '../../icons/solid/Trash';
import StarSolid from '../../icons/solid/Star';
import Star from '../../icons/outline/Star';
import Check from '../../icons/outline/Check';
import { motion } from 'framer-motion';

const ButtonContainer = styled.div`
  /* margin: 0.75rem auto; */
  width: ${(p) => p.width || null};

  @media (min-width: 599px) {
    max-width: 25rem;
  }
  ${(p) => p.label && labelStyles}
`;

const PrimaryStyles = css`
  background: ${(p) => p.color};
  color: ${(p) => p.theme.colors.background};
  border: none;
  transition: all 200ms ease;

  &:hover {
    background: ${(p) => p.lightColor};
    /* color: ${(p) => p.color}; */
    color: ${(p) => p.theme.colors.primaryText};
  }
`;

const SecondaryStyles = css`
  background: ${(p) => p.lightColor};
  /* color: ${(p) => p.theme.colors.primaryText}; */
  color: ${(p) => p.color};
  border: none;
  transition: all 200ms ease;

  &:hover {
    background: ${(p) => p.color};
    color: ${(p) => p.theme.colors.background};
  }
`;

const labelStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonLabel = styled.p`
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: 600;
`;

const Button = styled.button`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => p.color};
  color: ${COLORS.background};
  width: ${(p) => p.size || '2.2rem'};
  height: ${(p) => p.size || '2.2rem'};
  padding: 0.4rem;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 600;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  transition-property: background, box-shadow;
  transition-duration: 0.35s;
  margin: 0 0.4rem;

  &:disabled {
    color: #555455;
    background: #302e30;
    cursor: not-allowed;
    border: none;
  }

  ${(p) => p.primary && PrimaryStyles}
  ${(p) => p.secondary && SecondaryStyles}
`;

export default function CircleButton({
  color,
  primary,
  width,
  disabled,
  clickAction,
  square,
  loading,
  type,
  link,
  icon,
  size,
  label,
  secondary,
}) {
  let buttonColor, lightColor, renderedIcon;
  let iconColor = COLORS.background;

  switch (color) {
    case 'primary':
      buttonColor = COLORS.primary;
      lightColor = COLORS.primaryLight;
      break;
    case 'secondary':
      buttonColor = COLORS.secondary;
      lightColor = COLORS.secondaryLight;
      break;
    case 'black':
      buttonColor = COLORS.primaryText;
      lightColor = COLORS.primaryTextSoft;
      break;
    case 'warning':
      buttonColor = COLORS.warning;
      lightColor = COLORS.warningSoft;
      break;
    case 'error':
      buttonColor = COLORS.error;
      lightColor = COLORS.errorSoft;
      break;
    case 'success':
      buttonColor = COLORS.success;
      lightColor = COLORS.successSoft;
      break;
    case 'google':
      buttonColor = '#db3236';
      break;
    default:
      buttonColor = COLORS.secondaryText;
      lightColor = COLORS.secondaryTextSoft;
      break;
  }

  if (secondary) {
    iconColor = buttonColor;
  }

  switch (icon) {
    case 'plus':
      renderedIcon = <Plus color={iconColor} />;
      break;
    case 'pencil':
      renderedIcon = <Pencil color={iconColor} />;
      break;
    case 'trash':
      renderedIcon = <Trash color={iconColor} />;
      break;
    case 'star-solid':
      renderedIcon = <StarSolid color={iconColor} />;
      break;
    case 'star':
      renderedIcon = <Star color={iconColor} />;
      break;
    case 'edit':
      renderedIcon = <Adjustments color={iconColor} />;
      break;
    case 'x':
      renderedIcon = <Remove color={iconColor} />;
      break;
    case 'check':
      renderedIcon = <Check color={iconColor} />;
      break;
    default:
      renderedIcon = <RightArrow color={iconColor} />;
  }

  return (
    <ButtonContainer
      whileTap={{ scale: 0.9 }}
      as={link ? StyledLink : motion.div}
      color={primary ? COLORS.background : buttonColor}
      to={link}
    >
      <Button
        loading={loading}
        type={type}
        width={width}
        primary={primary}
        secondary={secondary}
        disabled={disabled}
        onClick={clickAction}
        color={buttonColor}
        square={square}
        lightColor={lightColor}
        size={size}
      >
        {loading ? (
          <LoadingButtonSpinner />
        ) : (
          <Fragment>{renderedIcon}</Fragment>
        )}
      </Button>
      {label && <ButtonLabel>{label}</ButtonLabel>}
    </ButtonContainer>
  );
}
