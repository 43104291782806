import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ButtonGroup } from '../../components/ButtonGroup';
import { Message } from '../../components/Text';
import { handleDeleteCourse } from '../../util/courseUtil';
import ButtonComponent from '../buttons/ButtonComponent';
import { closeModal } from './modalReducer';
import ModalWrapper from './ModalWrapper';

export default function ConfirmAssignmentDeletionModal({ course }) {
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <ModalWrapper header="Are you sure?">
      <Message>Are you sure you want to delete this course?</Message>
      <ButtonGroup>
        <ButtonComponent
          clickAction={() => dispatch(closeModal())}
          primary
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          style={{ marginLeft: '1rem' }}
          primary
          color="error"
          clickAction={() => handleDeleteCourse(course.id, history)}
        >
          Delete
        </ButtonComponent>
      </ButtonGroup>
    </ModalWrapper>
  );
}
