import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../../components/Text';
import { COLORS } from '../../layout/constants';

const Input = styled.input`
  border: none;
  padding: 1rem 1.25rem;
  background-color: ${COLORS.background};
  font-weight: 500;
  flex: 3;
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid ${(p) => p.theme.colors.tertiaryText};

  &:focus,
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${COLORS.secondaryText};
    font-weight: 400;
    font-size: 1rem;
  }
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
  flex: 1 0 1.25rem;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.primaryText};
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default function TextInput({
  label,
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  name,
  type,
}) {
  return (
    <Fragment>
      <InputWrapper>
        <Label>{label}</Label>
        <Input
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          type={type}
        />
      </InputWrapper>
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
}
