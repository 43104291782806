import React from 'react';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import { MessageContainer } from '../../app/components/CourseComponents';
import { Message } from '../../app/components/Text';

export default function NoAssignmentsMessage({ assignments, predicate }) {
  let message;
  let button = false;

  switch (predicate.get('filter')) {
    case 'upcoming':
      message = "You don't have any upcoming assignments!";
      button = true;
      break;
    case 'completed':
      message = "You don't have any completed assignments";
      break;
    case 'exam':
      message = "You don't have any exams due";
      break;
    case 'homework':
      message = "You don't have any homework due";
      break;
    case 'project':
      message = "You don't have any projects due";
      break;
    case 'overdue':
      message = "You don't have any overdue assignments";
      break;
    default:
      message = "You don't have any assignments!";
      button = true;
  }
  return (
    <div>
      {assignments.length === 0 ? (
        <MessageContainer>
          <Message>{message}</Message>
          {button && (
            <ButtonComponent primary color="primary" link="/course-search">
              Find A Course
            </ButtonComponent>
          )}
        </MessageContainer>
      ) : null}
    </div>
  );
}
