import React, { Fragment } from 'react';
import AssignmentListItem from './AssignmentListItem';
import { destructureMaps, sortDateGroup } from '../../app/util/dateUtil';
import { format } from 'date-fns';
import {
  AssignmentGroup,
  AssignmentGroupContainer,
} from '../../app/components/AssignmentComponents';
import { DateGroupTitle } from '../../app/components/Titles';
import { LoadingSpinner } from '../../app/components/LoadingSpinner';
import InfiniteScroll from 'react-infinite-scroller';
import { motion } from 'framer-motion';

export default function UserAssignmentsList({
  assignments,
  moreAssignments,
  loading,
  getNextAssignments,
}) {
  const dateGroups = destructureMaps(sortDateGroup(assignments));

  return (
    <Fragment>
      <AssignmentGroupContainer>
        {assignments.length !== 0 && (
          <InfiniteScroll
            pageStart={0}
            loadMore={getNextAssignments}
            hasMore={!loading && moreAssignments}
            initialLoad={false}
            loader={<LoadingSpinner />}
          >
            {dateGroups.map((dateGroup, i) => (
              <AssignmentGroup
                as={motion.div}
                initial={{ x: 0, y: 60, opacity: 0 }}
                animate={{
                  x: 0,
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: 'spring',
                    stiffness: 60,
                    mass: 0.5,
                    damping: 8,
                  },
                }}
                key={i}
              >
                <DateGroupTitle>
                  {format(dateGroup[0].dueDate, 'iiii, LLLL do')}
                </DateGroupTitle>
                {dateGroup.map((assignment) => (
                  <AssignmentListItem
                    key={assignment.id}
                    assignment={assignment}
                  />
                ))}
              </AssignmentGroup>
            ))}
          </InfiniteScroll>
        )}
      </AssignmentGroupContainer>
    </Fragment>
  );
}
