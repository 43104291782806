import styled from 'styled-components';

export const Badge = styled.div`
  padding: 0.25rem 0.75rem;
  width: fit-content;
  border-radius: 8px;
  /* border: 1px solid ${(p) => p.theme.colors.border}; */
  background: ${(p) => p.theme.colors.tertiaryText};
  opacity: 0.75;
  color: ${(p) => p.theme.colors.background};
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0.25rem 0;
`;
