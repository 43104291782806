import styled from 'styled-components';

export const AppWrapper = styled.div`
  height: 100%;
  margin: 0 auto;
`;

export const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
`;

export const HeaderTextWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2 1 auto;
  margin-left: 1rem;
`;

export const Main = styled.main`
  margin-top: 4rem;
  margin-bottom: 5rem;
  padding: 0 16px;
  height: ${(p) => p.height || 'auto'};
  @media (min-width: 599px) {
    max-width: 30rem;
    margin: auto;
    margin-top: 6rem;
    margin-bottom: 5rem;
  }
`;

export const MainNoTop = styled.main`
  margin-bottom: 9rem;
  padding: 0 16px;

  @media (min-width: 599px) {
    max-width: 30rem;
    margin: auto;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CenteredDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;
