import styled, { keyframes } from 'styled-components';

const button_loading_spinner = keyframes`
    from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const LoadingButtonSpinner = styled.div`
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: ${button_loading_spinner} 1s ease infinite;
`;
