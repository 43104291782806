import React, { Fragment } from 'react';
import { useField, useFormikContext } from 'formik';
import { InputWrapper, Label } from './LoginTextInput';
import { ErrorMessage } from '../../components/Text';
import TimePicker from 'react-time-picker';
import styled from 'styled-components';
import 'react-time-picker/dist/TimePicker.css';

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  .react-time-picker__inputGroup {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
  .react-time-picker__inputGroup input {
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
  }
  .react-time-picker__wrapper {
    padding: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colors.tertiaryText};
    color: ${(p) => p.theme.colors.primaryText};
  }

  .react-time-picker__wrapper input,
  .react-time-picker__wrapper select {
    color: ${(p) => p.theme.colors.primaryText};
  }
  &:focus,
  &:hover {
    outline: none;
  }
`;

export default function TimeInput({ onChange, value, error, id, ...props }) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <Fragment>
      <InputWrapper>
        <Label>{props.label}</Label>
        <StyledTimePicker
          {...field}
          onChange={(val) => {
            console.log(val);
            setFieldValue('dueTime', val);
          }}
          value={value}
          disableClock
          format="hh:mm a"
          amPmAriaLabel="PM"
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
}
