import React from 'react';
import styled from 'styled-components';

const SettingsSectionContainer = styled.section`
  display: block;
  margin: 2rem 0;
`;

const SettingsSectionTitle = styled.h2`
  font-weight: 500;
  font-size: 1rem;
  color: ${(p) => p.theme.colors.secondaryText};
  margin-bottom: 0.5rem;
`;

export default function SettingsSection({ title, children }) {
  return (
    <SettingsSectionContainer>
      <SettingsSectionTitle>{title}</SettingsSectionTitle>
      {children}
    </SettingsSectionContainer>
  );
}
