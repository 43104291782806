import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import { useHistory } from 'react-router';
import {
  registerInFirebase,
} from '../../app/firestore/authService';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import { PageTitleCenter } from '../../app/components/Titles';
import { AltLink, FormWrapper } from '../../app/components/FormComponents';
import StyledForm from '../../app/components/StyledForm';
import { ErrorMessage } from '../../app/components/Text';
import { COLORS } from '../../app/layout/constants';
import StyledLink from '../../app/components/StyledLink';
import { Main } from '../../app/components/Wrappers';

export default function SignUp() {
  const history = useHistory();

  return (
    <Main>
      <PageTitleCenter>Sign Up</PageTitleCenter>
      <FormWrapper>
        <Formik
          initialValues={{
            email: '',
            password: '',
            displayName: '',
            type: 'Student',
          }}
          validationSchema={Yup.object({
            email: Yup.string().required('Required').email('Invalid Email'),
            password: Yup.string().required('Required').min(6, 'Too short!'),
            // password2: Yup.string()
            //   .required()
            //   .oneOf([Yup.ref('password1'), null], 'Passwords do not match'),
            displayName: Yup.string().required('Required').max(25, 'Too Long!'),
            type: Yup.string().required(),
          })}
          onSubmit={async (data, { setSubmitting, setErrors }) => {
            try {
              console.log(data);
              await registerInFirebase(data);
              history.push('/dash');
            } catch (error) {
              setErrors({ auth: error.message });
              toast.error(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            handleChange,
            handleBlur,
            isSubmitting,
            isValid,
            dirty,
            errors,
            values,
            touched,
          }) => (
            <StyledForm width="90%">
              <LoginTextInput
                label="Name"
                name="displayName"
                placeholder="Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.displayName}
                error={
                  errors.displayName && touched.displayName
                    ? errors.displayName
                    : null
                }
              />
              <LoginTextInput
                label="Email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                type="email"
                error={errors.email && touched.email ? errors.email : null}
              />
              <LoginTextInput
                label="Password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                type="password"
                error={
                  errors.password && touched.password ? errors.password : null
                }
              />
              {errors.auth ? <ErrorMessage>{errors.auth}</ErrorMessage> : null}
              <ButtonComponent
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
                width="100%"
                loading={isSubmitting}
                primary
                color="primary"
              >
                Sign Up
              </ButtonComponent>
              <AltLink>
                <StyledLink to="/reset-password">Forgot Password?</StyledLink>
              </AltLink>
              <AltLink>
                <StyledLink style={{ color: COLORS.primary }} to="/login">
                  Login Instead
                </StyledLink>
              </AltLink>
            </StyledForm>
          )}
        </Formik>
      </FormWrapper>
    </Main>
  );
}
