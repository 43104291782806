import firebase from '../config/firebase';
import {
    startOfToday,
    startOfTomorrow,
    endOfToday,
    endOfTomorrow,
    add,
} from 'date-fns';
const db = firebase.firestore();

export function listenToSelectedAssignmentFromFirestore(assignmentId) {
    //need way to determine if assignment is from the course or from the user profile
    const user = firebase.auth().currentUser;
    return db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .doc(assignmentId);
}

//retrieves user assignments that are due that day
export function listenToTodaysAssignmentsFromFirestore() {
    const user = firebase.auth().currentUser;

    const start = startOfToday();
    const end = endOfToday();

    return db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .where('dueDate', '>=', start)
        .where('dueDate', '<=', end)
        .where('completed', '==', false)
        .orderBy('dueDate');
}

//retrieves assignments due the next day
export function listenToTomorrowsAssignmentsFromFirestore() {
    const user = firebase.auth().currentUser;

    const start = startOfTomorrow();
    const end = endOfTomorrow();

    return db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .orderBy('dueDate')
        .where('dueDate', '>=', start)
        .where('dueDate', '<=', end)
        .where('completed', '==', false);
}

//retrieves all overdue assignments
export function listenToOverdueAssignmentsFromFirestore() {
    const user = firebase.auth().currentUser;

    const now = new Date();
    const timestamp = firebase.firestore.Timestamp.fromDate(now);

    return db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .orderBy('dueDate')
        .where('dueDate', '<', timestamp)
        .where('completed', '==', false);
}

export function listenToImportantAssignmentsFromFirestore() {
    const user = firebase.auth().currentUser;

    const now = new Date();
    const end = add(now, {
        weeks: 1,
    });

    return db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .orderBy('dueDate')
        .where('type', '==', 'Exam' || 'Quiz' || 'Project')
        .where('dueDate', '>=', now)
        .where('dueDate', '<=', end)
        .where('completed', '==', false);
}

//retrieves all user assignments from firestore (not from courses collection but from users collection)
export function fetchUserAssignmentsFromFirestore(
    predicate,
    limit,
    lastDocSnapshot = null
) {
    const user = firebase.auth().currentUser;
    const now = new Date();
    const assignmentsRef = db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .orderBy('dueDate')
        .startAfter(lastDocSnapshot)
        .limit(limit);
    switch (predicate.get('filter')) {
        case 'upcoming':
            return assignmentsRef
                .where('completed', '==', false)
                .where('dueDate', '>=', now);
        case 'completed':
            return assignmentsRef.where('completed', '==', true);
        case 'exam':
            return assignmentsRef
                .where('type', '==', 'Exam')
                .where('completed', '==', false);
        case 'homework':
            return assignmentsRef
                .where('type', '==', 'Homework')
                .where('completed', '==', false);
        case 'project':
            return assignmentsRef
                .where('type', '==', 'Project')
                .where('completed', '==', false);
        case 'quiz':
            return assignmentsRef
                .where('type', '==', 'Quiz')
                .where('completed', '==', false);
        case 'lab':
            return assignmentsRef
                .where('type', '==', 'Lab')
                .where('completed', '==', false);
        case 'overdue':
            return assignmentsRef
                .where('dueDate', '<=', now)
                .where('completed', '==', false);
        default:
            return assignmentsRef;
    }
}

export async function assignmentCompletedFirestore(score, assignment) {
    const user = firebase.auth().currentUser;
    const batch = db.batch();
    try {
        batch.update(db.collection('users').doc(user.uid), {
            studyScore: firebase.firestore.FieldValue.increment(score),
        });
        batch.update(
            db
                .collection('users')
                .doc(user.uid)
                .collection('assignments')
                .doc(assignment.id),
            {
                completed: true,
                score: score,
                completedOn: firebase.firestore.FieldValue.serverTimestamp(),
            }
        );
        return await batch.commit();
    } catch (error) {
        throw error;
    }
}

export async function assignmentIncompleteFirestore(assignment) {
    const user = firebase.auth().currentUser;

    const batch = db.batch();
    try {
        batch.update(db.collection('users').doc(user.uid), {
            studyScore: firebase.firestore.FieldValue.increment(
                -assignment.score
            ),
        });
        batch.update(
            db
                .collection('users')
                .doc(user.uid)
                .collection('assignments')
                .doc(assignment.id),
            {
                completed: false,
                score: 0,
            }
        );
        return await batch.commit();
    } catch (error) {
        throw error;
    }
}
