import styled, { css } from 'styled-components';
import { COLORS } from '../../layout/constants';
import React, { Children, Fragment } from 'react';
import { LoadingButtonSpinner } from '../../components/LoadingButtonSpinner';
import { Message } from '../../components/Text';
import StyledLink from '../../components/StyledLink';
import { motion } from 'framer-motion';

const PrimaryStyles = css`
  background: ${(p) => p.color};
  color: ${(p) => p.theme.colors.background};
  border: none;
  transition: all 200ms ease;

  &:hover {
    background: ${(p) => p.lightColor};
    color: ${(p) => p.color};
    /* color: ${(p) => p.theme.colors.primaryText}; */
  }
`;

const SecondaryStyles = css`
  background: ${(p) => p.lightColor};
  /* color: ${(p) => p.theme.colors.primaryText}; */
  color: ${(p) => p.color};
  border: none;
  transition: all 200ms ease;

  &:hover {
    background: ${(p) => p.color};
    color: ${(p) => p.theme.colors.background};
  }
`;

const ButtonContainer = styled.div`
  margin: 0.75rem auto;
  width: ${(p) => p.width || null};

  @media (min-width: 599px) {
    max-width: 30rem;
  }
`;

const Button = styled.button`
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: ${(p) => p.width || null};
  padding: 0.75rem 1.75rem;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 600;
  border: 0;
  border-radius: 0.5rem;
  background: transparent;
  border: 3px solid ${(p) => p.color};
  color: ${(p) => p.color};
  cursor: pointer;
  outline: none;
  transition-property: background, box-shadow;
  transition-duration: 0.35s;
  margin: auto;
  transition: transform 0.2s ease-in-out;

  &:disabled {
    color: #555455;
    background: #302e30;
    cursor: not-allowed;
    border: none;
  }

  ${(p) => p.primary && PrimaryStyles}
  ${(p) => p.childCount > 1 && MultiChildStyles}
  ${(p) => p.secondary && SecondaryStyles}
`;

const MultiChildStyles = css`
  justify-content: center;
  & ${Button}:nth-child(1) {
    margin-right: 1rem;
  }
`;

export default function ButtonComponent({
  color,
  primary,
  width,
  disabled,
  clickAction,
  loading,
  type,
  link,
  subtext,
  children,
  secondary,
}) {
  let buttonColor, lightColor;

  const childCount = Children.count(children);

  switch (color) {
    case 'primary':
      buttonColor = COLORS.primary;
      lightColor = COLORS.primaryLight;
      break;
    case 'secondary':
      buttonColor = COLORS.secondary;
      lightColor = COLORS.secondaryLight;
      break;
    case 'black':
      buttonColor = COLORS.primaryText;
      lightColor = COLORS.primaryTextSoft;
      break;
    case 'warning':
      buttonColor = COLORS.warning;
      lightColor = COLORS.warningSoft;
      break;
    case 'error':
      buttonColor = COLORS.error;
      lightColor = COLORS.errorSoft;
      break;
    case 'success':
      buttonColor = COLORS.success;
      lightColor = COLORS.successSoft;
      break;
    case 'google':
      buttonColor = '#db3236';
      break;
    default:
      buttonColor = COLORS.secondaryText;
      lightColor = COLORS.secondaryTextSoft;
      break;
  }
  return (
    <ButtonContainer
      whileTap={{ scale: 0.9 }}
      as={link ? StyledLink : motion.div}
      color={primary ? COLORS.background : buttonColor}
      to={link}
    >
      <Button
        loading={loading}
        type={type}
        width={width}
        primary={primary}
        secondary={secondary}
        disabled={disabled}
        onClick={clickAction}
        color={buttonColor}
        childCount={childCount}
        lightColor={lightColor}
      >
        {loading ? <LoadingButtonSpinner /> : <Fragment>{children}</Fragment>}
      </Button>
      {subtext && <Message>{subtext}</Message>}
    </ButtonContainer>
  );
}
