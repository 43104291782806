import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const linkButtonStyles = css`
  display: block;
  margin: auto;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.color};
  width: ${(p) => p.width || null};
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  ${(p) => p.button && linkButtonStyles}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <StyledLink {...props} />;
