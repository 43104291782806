import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { Fragment } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import { InputWrapper, Label } from './LoginTextInput';
import { ErrorMessage } from '../../components/Text';

export default function DateRangeInput({
  startDate,
  endDate,
  error,
  id,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const [focused, setFocused] = useState(null);
  const [dates, setDates] = useState({
    fieldStartDate: startDate,
    fieldEndDate: endDate,
  });

  function isOutsideRange(day) {
    day.isAfter(moment()) || day.isBefore(moment().subtract(30, 'days'));
  }

  function onDatesChange({ startDate, endDate }) {
    if (startDate && !endDate) {
      setDates({
        fieldStartDate: startDate.toDate(),
      });
      setFieldValue('startDate', startDate.toDate());
    } else if (endDate) {
      setDates({
        fieldStartDate: startDate.toDate(),
        fieldEndDate: endDate.toDate(),
      });
      setFieldValue('startDate', startDate.toDate());
      setFieldValue('endDate', endDate.toDate());
    }
  }

  return (
    <Fragment>
      <InputWrapper>
        <Label>{props.label}</Label>
        <DateRangePicker
          small
          numberOfMonths={1}
          required
          noBorder
          startDate={dates.fieldStartDate && moment(dates.fieldStartDate)}
          startDatePlaceholderText="Start Date"
          startDateId={props.startDateId}
          endDate={dates.fieldEndDate && moment(dates.fieldEndDate)}
          endDateId={props.endDateId}
          endDatePlaceholderText="End Date"
          onDatesChange={onDatesChange}
          focusedInput={focused}
          hideKeyboardShortcutsPanel
          onFocusChange={(focusedInput) => {
            setFocused(focusedInput);
          }}
          openDirection="up"
          isOutsideRange={isOutsideRange}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
}
