import React from 'react';
import { useDispatch } from 'react-redux';
import { Message } from '../../components/Text';
import ButtonComponent from '../buttons/ButtonComponent';
import { closeModal } from './modalReducer';
import ModalWrapper from './ModalWrapper';

export default function CompletedAssignmentModal({ score }) {
  const dispatch = useDispatch();

  return (
    <ModalWrapper header="Assignment Completed!">
      <Message>You earned {score} points!</Message>
      <ButtonComponent
        width="100%"
        clickAction={() => dispatch(closeModal())}
        primary
        color="primary"
      >
        Ok
      </ButtonComponent>
    </ModalWrapper>
  );
}
