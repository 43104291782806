import styled, { css } from 'styled-components';
import { COLORS, SHADOW } from '../layout/constants';

export const AssignmentGroupContainer = styled.div`
  margin-bottom: 3rem;
`;

export const AssignmentGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  margin-bottom: 1.25rem;
  background: ${(p) => p.theme.colors.white};
  /* background: linear-gradient(
    to bottom right,
    ${COLORS.gradientStart},
    ${COLORS.gradientEnd}
  ); */
  box-shadow: ${SHADOW.normal};
  padding: 1.35rem 1.2rem;
  /* border: 1px solid ${(p) => p.theme.colors.border}; */
  border-radius: 12px;

  @media (min-width: 700px) {
    padding: 2rem 2.75rem;
  }
`;

export const completedStyles = css`
  color: ${COLORS.secondaryText};
`;

export const completedOpacity = css`
  opacity: 0.3;
`;

export const examStyles = css`
  font-weight: 600;
  color: ${(p) => p.theme.colors.warning};
`;

export const overdueStyles = css`
  font-weight: 600;
  color: ${(p) => p.theme.colors.error};
`;

export const nearlyDueStyles = css`
  font-weight: 700;
  color: ${(p) => p.theme.colors.warning};
`;

export const AssignmentRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;

  ${(p) => p.completed && completedStyles}
`;

export const CheckboxContainer = styled.div`
  width: 25px;
  max-width: 25px;
  min-width: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-basis: 25px;
  margin-right: 12px;
`;

export const AssignmentRowInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const AssignmentTitleRowContainer = styled.div`
  width: 175px;
  max-width: 175px;
  min-width: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  flex-basis: 175px;
  ${(p) => p.completed && completedOpacity};
`;

export const AssignmentTimeRowContainer = styled.div`
  width: 70px;
  max-width: 70px;
  min-width: 70px;
  display: flex;
  flex-basis: 70px;
  justify-content: flex-end;
  align-items: center;
`;

export const AssignmentTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  width: 175px;
  color: ${COLORS.primaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(p) => p.exam && examStyles}
  ${(p) => p.overdue && overdueStyles}
`;

export const AssignmentCourse = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  width: 175px;
  color: ${COLORS.secondaryText};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const AssignmentTime = styled.p`
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  color: ${COLORS.primaryText};
  ${(p) => p.nearlyDue && nearlyDueStyles}
  ${(p) => p.completed && completedOpacity}
`;

export const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;
