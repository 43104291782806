import React, { Fragment } from 'react';
import { COLORS } from '../../app/layout/constants';
import { CardHeading } from '../../app/components/Titles';
import { Message } from '../../app/components/Text';
import styled from 'styled-components';
import CoursesList from '../courses/CoursesList';
import { motion } from 'framer-motion';

export const CourseGroup = styled.div`
  margin-bottom: 0.5rem;
  width: 100%;
`;

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export default function ProfileCourses({ profile }) {
  const { courses, createdCourses } = profile;
  return (
    <Fragment>
      {courses.length > 1 && createdCourses.length < 1 && (
        <CourseGroup style={{ marginBottom: '2rem' }}>
          <CardHeading color={COLORS.primary}>Enrolled Courses</CardHeading>
          {!courses || courses.length < 1 ? (
            <Message>
              {profile.displayName} isn't following any courses.
            </Message>
          ) : null}
          <motion.div
            variants={containerVariants}
            animate="visible"
            initial="hidden"
            exit="exit"
          >
            <CoursesList courses={courses} />
          </motion.div>
        </CourseGroup>
      )}
      <CourseGroup style={{ marginBottom: '6rem' }}>
        <CardHeading color={COLORS.primary}>Created Courses</CardHeading>
        {!createdCourses || createdCourses.length < 1 ? (
          <Message>{profile.displayName} hasn't created any courses.</Message>
        ) : null}
        <motion.div
          variants={containerVariants}
          animate="visible"
          initial="hidden"
          exit="exit"
        >
          <CoursesList courses={createdCourses} />
        </motion.div>
      </CourseGroup>
    </Fragment>
  );
}
