import React from 'react';
import styled from 'styled-components';
import { COLORS, SIZES } from '../../app/layout/constants';
import CourseItem from './CourseItem';

const CoursesListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  justify-items: center;
  grid-gap: ${SIZES.lengthMd1};
  @media (min-width: 599px) {
    grid-gap: ${SIZES.lengthMd3};
  } ;
`;

export default function CoursesList({ courses }) {
  return (
    <CoursesListContainer>
      {courses &&
        courses.map((course, i) => {
          return (
            <CourseItem
              course={course}
              key={course.id}
              color={COLORS.primary}
            />
          );
        })}
    </CoursesListContainer>
  );
}
