import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const DashboardGreetingContainer = styled.section`
  margin: 2rem 0;
`;

const DashboardGreetingTitle = styled.h2`
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const GreetingText = styled.h5`
  color: ${(p) => p.theme.colors.secondaryText};
  font-size: 1rem;
  font-weight: 500;
`;

export default function DashboardGreeting({ displayName }) {
  const now = new Date();
  let timeOfDay = '';

  const firstName = displayName.substring(0, displayName.indexOf(' '));

  if (now.getHours() >= 0 && now.getHours() < 12) {
    timeOfDay = 'Morning';
  } else if (now.getHours() >= 12 && now.getHours() < 18) {
    timeOfDay = 'Afternoon';
  } else {
    timeOfDay = 'Evening';
  }

  return (
    <DashboardGreetingContainer>
      <DashboardGreetingTitle
        as={motion.h2}
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
      >
        Good {timeOfDay}, {firstName}
      </DashboardGreetingTitle>
      <GreetingText
        as={motion.h2}
        animate={{ y: 0, x: 0, opacity: 1 }}
        initial={{ y: 20, x: 0, opacity: 0 }}
      >
        Here's what's due
      </GreetingText>
    </DashboardGreetingContainer>
  );
}
