import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import { useDispatch, useSelector } from 'react-redux';
import { listenToSelectedCourse } from '../courses/courseActions';
import { toast } from 'react-toastify';
import useFirestoreDoc from '../../app/hooks/useFirestoreDoc';
import {
  addCourseToFirestore,
  fetchCourseFromFirestore,
  updateCourseInFirestore,
} from '../../app/firestore/courseService';
import LoadingComponent from '../../app/common/LoadingComponent';
import Header from '../../app/common/Header';
import Media from 'react-media';
import { GLOBAL_MEDIA_QUERIES } from '../../app/layout/constants';
import { PageTitle } from '../../app/components/Titles';
import CourseFormInputs from './CourseFormInputs';
import { Main } from '../../app/components/Wrappers';

const CourseForm = styled.form`
  width: 100%;
  margin-bottom: 3rem;
`;

export default function CreateCourse({ match, history }) {
  const dispatch = useDispatch();

  const { selectedCourse } = useSelector((state) => state.course);
  const { loading } = useSelector((state) => state.async);

  let initialValues = selectedCourse ?? {
    title: '',
    sectionTitle: '',
    professorName: '',
    school: '',
    professorEmail: '',
    startDate: new Date(),
    endDate: new Date(),
    meetingTime: '',
    description: '',
  };

  const validationSchema = Yup.object({
    title: Yup.string().required('Required!'),
    sectionTitle: Yup.string().required('Required!'),
    professorName: Yup.string().required('Required!'),
    school: Yup.string().required('Required!'),
    professorEmail: Yup.string().required('Required!').email('Invalid Email'),
    startDate: Yup.string().required('Required!'),
    endDate: Yup.string().required('Required!'),
    meetingTime: Yup.string().required('Required!'),
    description: Yup.string().notRequired('Required!'),
  });

  useFirestoreDoc({
    shouldExecute: !!match.params.id,
    query: () => fetchCourseFromFirestore(match.params.id),
    data: (course) => dispatch(listenToSelectedCourse(course)),
    deps: [match.params.id, dispatch],
  });

  if (loading) return <LoadingComponent />;

  return (
    <Fragment>
      <Header showBackBtn>
        {selectedCourse ? 'Edit Course' : 'Create Course'}
      </Header>
      <Main>
        <Media queries={GLOBAL_MEDIA_QUERIES}>
          {(matches) => (
            <Fragment>
              {(matches.medium || matches.large) && (
                <PageTitle>
                  {selectedCourse ? 'Edit Course' : 'Create Course'}
                </PageTitle>
              )}
            </Fragment>
          )}
        </Media>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (data, { setSubmitting, setErrors }) => {
            try {
              if (selectedCourse) {
                await updateCourseInFirestore(data);
                history.push(`/courses/${match.params.id}`);
              } else {
                await addCourseToFirestore(data);
                history.push('/courses');
              }
            } catch (error) {
              setErrors({ form: error.message });
              toast.error(error.message);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            errors,
            touched,
          }) => (
            <CourseForm onSubmit={handleSubmit}>
              <CourseFormInputs
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                errors={errors}
                touched={touched}
              />
              {errors.form && <ErrorMessage>{errors.form}</ErrorMessage>}
              <ButtonComponent
                style={{ marginTop: '3rem' }}
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                primary
                color="secondary"
                type="submit"
                width="100%"
              >
                {selectedCourse ? 'Update Course' : 'Create Course'}
              </ButtonComponent>
            </CourseForm>
          )}
        </Formik>
      </Main>
    </Fragment>
  );
}
