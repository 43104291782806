import { isSameDay } from 'date-fns';
import { formatISO } from 'date-fns/esm';

//algorithm to sort assignments by date group
//parameters: assignments (array)
export function sortDateGroup(assignments) {
    const mapOfDateGroups = new Map();
    let dateGroups = [];
    assignments.forEach((assignment, i) => {
        let date = assignment.dueDate;

        const test = (testDate) => isSameDay(testDate, date);

        if (i !== 0) {
            if (!dateGroups.some(test)) {
                dateGroups.push(date);
            }
        } else {
            dateGroups.push(date);
        }
    });
    dateGroups.forEach((dateGroup, i) => {
        let assignmentsByDateGroup = [];
        assignments.forEach((assignment) => {
            if (isSameDay(assignment.dueDate, dateGroup)) {
                assignmentsByDateGroup.push(assignment);
            }
        });
        mapOfDateGroups.set(i, assignmentsByDateGroup);
    });

    return mapOfDateGroups;
}

export function destructureMaps(map) {
    const parentArr = [];
    for (let i = 0; i < map.size; i++) {
        const assignmentsArr = map.get(i);

        parentArr.push(assignmentsArr);
    }
    return parentArr;
}

export function handleSeperateDateTime(date, time) {
    const newDate = formatISO(date, { representation: 'date' });
    const dateTime = `${newDate}T${time}`;
    return new Date(dateTime);
}
