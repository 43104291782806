import { combineReducers } from 'redux';
import assignmentReducer from '../../features/assignments/assignmentReducer';
import asyncReducer from '../async/asyncReducer';
import courseReducer from '../../features/courses/courseReducer';
import authReducer from '../../features/auth/authReducer';
import profileReducer from '../../features/profile/profileReducer';
import modalReducer from '../common/modals/modalReducer';

const rootReducer = combineReducers({
    assignment: assignmentReducer,
    async: asyncReducer,
    course: courseReducer,
    auth: authReducer,
    profile: profileReducer,
    modals: modalReducer,
});

export default rootReducer;
