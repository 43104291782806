import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SIZES } from '../layout/constants';

export const TopNavContainer = styled.nav`
  z-index: 10;
  height: 48px;
  width: 100%;
  background: ${(p) => p.theme.colors.white};
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${(p) => p.theme.colors.primaryText};
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

export const TopNavLogo = styled.h1`
  font-size: 1.4rem;
  margin: 0 2rem;
`;

export const NavMenuMobile = styled.div`
  width: 100vw;
  height: 80px;
  background: ${(p) => p.theme.colors.white};
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  position: fixed;
  color: white;
  /* border-top: 1px solid #e6e6ee; */
  padding-bottom: 3rem;
  padding-top: 1rem;
  /* transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1); */
`;

export const NavMenuDesktop = styled.div`
  width: 100vw;
  height: 60px;
  background: ${(p) => p.theme.colors.white};
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3rem;
  position: fixed;
  color: white;
  border-bottom: 1px solid #e6e6ee;
  /* transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1); */
`;

export const DesktopLink = styled(NavLink)`
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${(p) => p.theme.colors.primaryText};
  font-weight: 500;
  text-decoration: none;
  margin: 0;
  padding: ${SIZES.lengthSm2};

  &:hover {
    background: ${(p) => p.theme.colors.secondaryTextSoft};
    border-radius: ${SIZES.lengthSm3};
  }

  &.active {
    color: ${(p) => p.theme.colors.primary};
    font-weight: 600;
  }
`;

export const StyledNavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  width: 100%;
  height: 100vh;
  margin: 0;
  list-style-type: none;
  flex: 1;
`;

export const StyledNavDrawer = styled.div`
  width: 25vw;
  z-index: 10;
  background: ${(p) => p.theme.colors.white};
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  /* border-left: 1px solid ${(p) => p.theme.colors.border}; */
  @media (min-width: 1200px) {
    width: 20vw;
  }
`;

export const StyledNavBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 5;
`;

export const NavDrawerInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${SIZES.lengthSm2};
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DrawerItems = styled.div`
  padding: ${SIZES.lengthMd3};
`;

export const StyledParagraph = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.primaryText};
  margin-left: ${SIZES.lengthSm1};
`;
