import React from 'react';
import {
  differenceInHours,
  format,
  isAfter,
  isBefore,
  isSameDay,
} from 'date-fns';
import CircularMark from '../../app/components/CircularMark';
import { COLORS } from '../../app/layout/constants';
import CheckIcon from '../../app/icons/solid/Check';
import StyledLink from '../../app/components/StyledLink';
import {
  calculateScore,
  handleSetCompleted,
} from '../../app/util/assignmentUtil';
import {
  AssignmentCourse,
  AssignmentRow,
  AssignmentRowInfoContainer,
  AssignmentTime,
  AssignmentTimeRowContainer,
  AssignmentTitle,
  AssignmentTitleRowContainer,
  CheckboxContainer,
  IconGroup,
} from '../../app/components/AssignmentComponents';
import { useHistory } from 'react-router';
import { openModal } from '../../app/common/modals/modalReducer';
import { useDispatch } from 'react-redux';
import CircleButton from '../../app/common/buttons/CircleButton';
import { motion } from 'framer-motion';

export default function AssignmentListItem({
  assignment,
  exam,
  editMode,
  showDate,
  hideCourseSection,
}) {
  const now = new Date();
  const history = useHistory();
  const dispatch = useDispatch();
  const { sectionTitle } = assignment.course;

  const { dueDate, completed, id, title } = assignment;

  const variants = {
    hidden: { opacity: 0, x: 25 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  function handleComplete() {
    // setCompletedAnimation(!completed);
    if (!completed) {
      dispatch(
        openModal({
          modalType: 'CompletedAssignmentModal',
          modalProps: { score: calculateScore(assignment) },
        })
      );
    }

    handleSetCompleted(assignment);
  }

  return (
    <AssignmentRow
      as={motion.div}
      variants={variants}
      animate="visible"
      completed={completed}
    >
      <CheckboxContainer onClick={handleComplete}>
        {!completed ? <CircularMark /> : <CheckIcon color={COLORS.success} />}
      </CheckboxContainer>
      <AssignmentRowInfoContainer>
        <AssignmentTitleRowContainer completed={completed}>
          <AssignmentTitle
            as={StyledLink}
            button
            to={`/assignments/${id}`}
            overdue={isBefore(dueDate, now) && !completed}
            exam={exam}
          >
            {title}
          </AssignmentTitle>
          {!hideCourseSection && (
            <AssignmentCourse as={StyledLink} button to={`/assignments/${id}`}>
              {sectionTitle}
            </AssignmentCourse>
          )}
        </AssignmentTitleRowContainer>
        <AssignmentTimeRowContainer>
          {editMode ? (
            <IconGroup>
              <CircleButton
                icon="pencil"
                secondary
                clickAction={() =>
                  dispatch(
                    openModal({
                      modalType: 'AssignmentFormModal',
                      modalProps: {
                        course: assignment.course,
                        selectedAssignment: assignment,
                      },
                    })
                  )
                }
              />
              <CircleButton
                icon="trash"
                secondary
                color="error"
                clickAction={() =>
                  dispatch(
                    openModal({
                      modalType: 'ConfirmAssignmentDelete',
                      modalProps: { assignment, history },
                    })
                  )
                }
              />
            </IconGroup>
          ) : (
            <StyledLink to={`/assignments/${id}`}>
              <AssignmentTime
                nearlyDue={
                  isSameDay(dueDate, now) &&
                  !completed &&
                  isAfter(dueDate, now) &&
                  differenceInHours(dueDate, now) <= 4
                }
                completed={completed}
              >
                {showDate
                  ? format(assignment.dueDate, 'M/d')
                  : format(assignment.dueDate, 'p')}
              </AssignmentTime>
            </StyledLink>
          )}
        </AssignmentTimeRowContainer>
      </AssignmentRowInfoContainer>
    </AssignmentRow>
  );
}
