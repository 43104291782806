import React from 'react';
import ReactDOM from 'react-dom';
import 'react-dates/initialize';
import './index.css';
import App from './app/layout/App.jsx';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { configureStore } from './app/store/configureStore';
import { Provider } from 'react-redux';
import ScrollToTop from './app/layout/ScrollToTop';

const store = configureStore();

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <React.StrictMode>
                    <ScrollToTop />
                    <App />
                </React.StrictMode>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
}

if (module.hot) {
    module.hot.accept('./app/layout/App', function () {
        setTimeout(render);
    });
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
