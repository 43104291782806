import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../layout/constants';
import Logo from './Logo';

const SpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const SpinnerText = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 2.4rem;
  color: ${COLORS.primaryText};
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
`;

const skBounce = keyframes`
    0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`;

const DoubleBounce1 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${COLORS.primary};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${skBounce} 2s infinite ease-in-out;
`;
const DoubleBounce2 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${COLORS.primary};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${skBounce} 2s infinite ease-in-out;
  animation-delay: -1s;
`;

export default function LoadingComponent({ content }) {
  return (
    <SpinnerContainer>
      <Spinner>
        <DoubleBounce1 />
        <DoubleBounce2 />
      </Spinner>
      {content && <SpinnerText>{content}</SpinnerText>}
    </SpinnerContainer>
  );
}
