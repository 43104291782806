import { SIGN_IN_USER, SIGN_OUT_USER } from './authConstants';
import firebase from '../../app/config/firebase';
import { getUserProfile } from '../../app/firestore/profileService';
import { listenToCurrentUserProfile } from '../profile/profileActions';
import { dataFromSnapshot } from '../../app/firestore/firestoreService';
import { APP_LOADED } from '../../app/async/asyncReducer';

export function signInUser(user) {
    return {
        type: SIGN_IN_USER,
        payload: user,
    };
}

export function verifyAuth() {
    return function (dispatch) {
        return firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.analytics().setUserId(user.uid);
                dispatch(signInUser(user));
                const profileRef = getUserProfile(user.uid);
                profileRef.onSnapshot((snapshot) => {
                    dispatch(
                        listenToCurrentUserProfile(dataFromSnapshot(snapshot))
                    );
                    dispatch({ type: APP_LOADED });
                });
            } else {
                dispatch({ type: SIGN_OUT_USER });
                dispatch({ type: APP_LOADED });
            }
        });
    };
}

export function signOutUser() {
    return {
        type: SIGN_OUT_USER,
    };
}
