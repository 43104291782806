import React from 'react';
import AssignmentListItem from '../assignments/AssignmentListItem';
import { DateGroupTitle } from '../../app/components/Titles';
import { Message } from '../../app/components/Text';
import {
  AssignmentGroup,
  AssignmentGroupContainer,
} from '../../app/components/AssignmentComponents';
import { motion } from 'framer-motion';

export default function DashboardList({ assignments, header }) {
  let title, message, color;
  let bottomMargin = '1rem';

  let showDate = false;

  switch (header) {
    case 'today':
      title = 'Today';
      message = "Relax. Nothing's due today!";
      break;
    case 'tomorrow':
      title = 'Tomorrow';
      message = 'No assignments tomorrow!';
      break;
    case 'overdue':
      title = 'Overdue';
      message = 'No overdue assignments!';
      bottomMargin = '6rem';
      showDate = true;
      break;
    case 'important':
      title = 'Important';
      showDate = true;
      break;
    default:
      title = 'Due Today';
  }

  const variants = {
    hidden: { opacity: 0, y: 25, x: 0 },
    visible: {
      opacity: 1,
      y: 0,
      x: 0,
      transition: {
        type: 'spring',
        mass: 0.5,
        damping: 8,
        stiffness: 120,
      },
    },
  };

  return (
    <AssignmentGroupContainer
      as={motion.div}
      variants={variants}
      initial="hidden"
      animate="visible"
      style={{ marginBottom: bottomMargin }}
    >
      <AssignmentGroup>
        <DateGroupTitle color={color}>{title}</DateGroupTitle>
        {assignments.map((a) => (
          <AssignmentListItem showDate={showDate} key={a.id} assignment={a} />
        ))}
        {assignments.length < 1 ? <Message>{message}</Message> : null}
      </AssignmentGroup>
    </AssignmentGroupContainer>
  );
}
