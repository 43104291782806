import styled, { keyframes } from 'styled-components';

const button_loading_spinner = keyframes`
    from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

export const LoadingSpinner = styled.div`
  content: '';
  /* position: absolute; */
  width: 16px;
  height: 16px;
  /* right: 0; */
  /* bottom: 0; */
  margin: auto;
  border: 4px solid transparent;
  border-top-color: ${(p) => p.theme.colors.primary};
  border-radius: 50%;
  animation: ${button_loading_spinner} 1s ease infinite;
`;
