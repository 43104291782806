import React from 'react';

import styled, { css } from 'styled-components';
import { COLORS, SHADOW } from '../../app/layout/constants';

const invertedStyles = css`
  box-shadow: ${SHADOW.neuInverted};
`;

const CardWrapper = styled.div`
  z-index: 1;
  /* background: linear-gradient(
    to bottom right,
    ${COLORS.gradientStart},
    ${COLORS.gradientEnd}
  ); */
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${SHADOW.normal};
  width: ${(p) => p.width || 'auto'};
  height: ${(p) => p.height || 'auto'};
  padding: 1.35rem 1.25rem;
  border-radius: 12px;
  margin-bottom: ${(p) => p.bottomMargin || 0};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  /* border: 1px solid ${(p) => p.theme.colors.border}; */
  padding: 1.75rem 0.75rem;
  @media (min-width: 599px) {
    max-width: 30rem;
    margin: auto;
    margin-bottom: 2rem;
  }
  ${(p) => p.inverted && invertedStyles};
`;

export default function Card(props) {
  return (
    <CardWrapper
      inverted={props.inverted}
      width={props.width}
      height={props.height}
      bottomMargin={props.bottomMargin}
    >
      {props.children}
    </CardWrapper>
  );
}
