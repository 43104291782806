import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyChdgVicyzB6LeVleZXDd7gBadt5OiKjSU',
    authDomain: 'studyflow-a115d.firebaseapp.com',
    databaseURL: 'https://studyflow-a115d.firebaseio.com',
    projectId: 'studyflow-a115d',
    storageBucket: 'studyflow-a115d.appspot.com',
    messagingSenderId: '694205438133',
    appId: '1:694205438133:web:b772c6dcf031ccd980c6fc',
    measurementId: 'G-K8CENMBKTD',
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
const analytics = firebase.analytics();

analytics.logEvent('notification_received');

export default firebase;
