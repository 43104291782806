import { logEvent } from '../config/analytics';
import firebase from '../config/firebase';
import { handleSeperateDateTime } from '../util/dateUtil';

const db = firebase.firestore();

export function fetchCourseFromFirestore(courseId) {
    return db.collection('courses').doc(courseId);
}

export function listenToCoursesFromFirestore() {
    return db.collection('courses');
}

export function listenToCourseAssignmentsFromFirestore(courseId) {
    return db
        .collection('courses')
        .doc(courseId)
        .collection('assignments')
        .orderBy('dueDate');
}

export function addCourseToFirestore(course) {
    const user = firebase.auth().currentUser;
    logEvent('course_created', { createdBy: user.uid });
    return db.collection('courses').add({
        ...course,
        createdBy: user.displayName,
        adminUid: user.uid,
        adminPhotoURL: user.photoURL || null,
        students: firebase.firestore.FieldValue.arrayUnion({
            id: user.uid,
            displayName: user.displayName,
            photoURL: user.photoURL || null,
        }),
        studentIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
    });
}

export function updateCourseInFirestore(course) {
    return db.collection('courses').doc(course.id).update(course);
}

export function addStudentToCourse(courseId) {
    const user = firebase.auth().currentUser;
    logEvent('joined_course', { user: user.uid });
    return db
        .collection('courses')
        .doc(courseId)
        .update({
            students: firebase.firestore.FieldValue.arrayUnion({
                id: user.uid,
                displayName: user.displayName,
                photoURL: user.photoURL || '/assets/user.png',
            }),
            studentIds: firebase.firestore.FieldValue.arrayUnion(user.uid),
        });
}

export async function removeStudentFromCourse(courseId) {
    const user = firebase.auth().currentUser;
    logEvent('left_course', { user: user.uid });
    try {
        const courseDoc = await db.collection('courses').doc(courseId).get();
        return db
            .collection('courses')
            .doc(courseId)
            .update({
                studentIds: firebase.firestore.FieldValue.arrayRemove(user.uid),
                students: courseDoc
                    .data()
                    .students.filter((student) => student.id !== user.uid),
            });
    } catch (error) {
        throw error;
    }
}

export function addAssignmentToCourseInFirestore(course, assignment) {
    logEvent('assignment_created');
    return db
        .collection('courses')
        .doc(course.id)
        .collection('assignments')
        .add({
            ...assignment,
            dueDate: handleSeperateDateTime(
                assignment.dueDay,
                assignment.dueTime
            ),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            completed: false,
            course: course,
        });
}

export function updateAssignmentInCourseInFirestore(
    course,
    newValues,
    assignmentId
) {
    return db
        .collection('courses')
        .doc(course.id)
        .collection('assignments')
        .doc(assignmentId)
        .update({
            ...newValues,
            dueDate: handleSeperateDateTime(
                newValues.dueDay,
                newValues.dueTime
            ),
        });
}

export function deleteAssignmentFromCourseFirestore(courseId, assignmentId) {
    return db
        .collection('courses')
        .doc(courseId)
        .collection('assignments')
        .doc(assignmentId)
        .delete();
}

export function deleteCourseInFirestore(courseId) {
    return db.collection('courses').doc(courseId).delete();
}

export function fetchCourseAssignmentsFromUserFirestore(
    courseId,
    predicate,
    limit,
    lastDocSnapshot = null
) {
    const user = firebase.auth().currentUser;
    const assignmentsRef = db
        .collection('users')
        .doc(user.uid)
        .collection('assignments')
        .orderBy('dueDate')
        .startAfter(lastDocSnapshot)
        .limit(limit);
    switch (predicate.get('filter')) {
        case 'exam':
            return assignmentsRef
                .where('type', '==', 'Exam')
                .where('course.id', '==', courseId);
        case 'homework':
            return assignmentsRef
                .where('type', '==', 'Homework')
                .where('course.id', '==', courseId);
        case 'project':
            return assignmentsRef
                .where('type', '==', 'Project')
                .where('course.id', '==', courseId);
        case 'assignment':
            return assignmentsRef.where('course.id', '==', courseId);
        default:
            return assignmentsRef.where('course.id', '==', courseId);
    }
}
