import { toast } from 'react-toastify';
import {
    addStudentToCourse,
    deleteCourseInFirestore,
    removeStudentFromCourse,
} from '../firestore/courseService';

export async function handleUserFollowCourse(setLoading, courseId) {
    setLoading(true);
    try {
        await addStudentToCourse(courseId);
    } catch (error) {
        toast.error(error.message);
    } finally {
        setLoading(false);
    }
}

export async function handleUserUnfollowCourse(setLoading, courseId) {
    setLoading(true);
    try {
        await removeStudentFromCourse(courseId);
    } catch (error) {
        toast.error(error.message);
    } finally {
        setLoading(false);
    }
}

export function handleDeleteCourse({ courseId, history }) {
    console.log(history);
    try {
        deleteCourseInFirestore(courseId);
        // history.push('/dash');
        toast.error('Course Deleted');
    } catch (error) {
        throw error;
    }
}
