import React, { useState, useEffect, Fragment } from 'react';
import CourseDetailedAssignments from './CourseDetailedAssignments';
import CourseDetailedHeader from './CourseDetailedHeader';
import CourseDetailedMenu from './CourseDetailedMenu';
import { CenteredDiv, Main } from '../../../app/components/Wrappers';
import Header from '../../../app/common/Header';
import { useDispatch, useSelector } from 'react-redux';
import Media from 'react-media';
import {
  clearCourseAssignments,
  fetchCourseAssignments,
  listenToSelectedCourse,
} from '../courseActions';
import ErrorComponent from '../../../app/common/errors/ErrorComponent';
import { CLEAR_SELECTED_COURSE } from '../courseConstants';
import { Message } from '../../../app/components/Text';
import { GLOBAL_MEDIA_QUERIES } from '../../../app/layout/constants';
import { openModal } from '../../../app/common/modals/modalReducer';
import ButtonComponent from '../../../app/common/buttons/ButtonComponent';
import LockClosed from '../../../app/icons/solid/LockClosed';
import { BottomRightAction } from '../../../app/components/BottomRightAction';
import CircleButton from '../../../app/common/buttons/CircleButton';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { fetchCourseFromFirestore } from '../../../app/firestore/courseService';
import LoadingSpinnerCentered from '../../../app/components/LoadingSpinnerCentered';

export default function CourseDetailed({ match }) {
  const limit = 8;
  const [activeTab, setActiveTab] = useState(0); //sets the active tab to 'Assignments'
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const course = useSelector((state) => state.course.selectedCourse);
  const assignments = useSelector(
    (state) => state.course.selectedCourseAssignments
  );
  const { loading, error } = useSelector((state) => state.async);
  const isAdmin = course?.adminUid === currentUser.uid;
  const isFollowing = course?.students?.some((s) => s.id === currentUser.uid);
  const [editMode, setEditMode] = useState(false);
  const { moreAssignments } = useSelector((state) => state.course);
  const [predicate, setPredicate] = useState(new Map([['filter', 'all']]));
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [lastDocSnapshot, setLastDocSnapshot] = useState(null);

  function handleSetPredicate(key, value) {
    dispatch(clearCourseAssignments());
    setLastDocSnapshot(null);
    setPredicate(new Map(predicate.set(key, value)));
  }

  useFirestoreDoc({
    query: () => fetchCourseFromFirestore(match.params.id),
    data: (course) => dispatch(listenToSelectedCourse(course)),
    deps: [dispatch, match.params.id],
  });

  //gets course and course assignments based on predicate, then clears course assignments and course on dismount
  useEffect(() => {
    setLoadingInitial(true);
    // dispatch(fetchCourse(match.params.id));
    dispatch(fetchCourseAssignments(match.params.id, predicate, limit)).then(
      (lastVisible) => {
        setLastDocSnapshot(lastVisible);
        setLoadingInitial(false);
      }
    );

    return () => {
      dispatch(clearCourseAssignments());
    };
  }, [dispatch, predicate, match.params.id]);

  //clear the course on component dismount
  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_SELECTED_COURSE });
    };
  }, [dispatch]);

  function handleFetchNextAssignments() {
    dispatch(
      fetchCourseAssignments(match.params.id, predicate, limit, lastDocSnapshot)
    ).then((lastVisible) => {
      setLastDocSnapshot(lastVisible);
    });
  }

  if (loadingInitial || (!course && !error)) return <LoadingSpinnerCentered />;

  if (error) return <ErrorComponent />;

  return (
    <Fragment>
      <Media queries={GLOBAL_MEDIA_QUERIES}>
        {(matches) => (
          <Fragment>
            {matches.small && (
              <Header
                showBackBtn
                action={
                  isAdmin
                    ? () =>
                        dispatch(
                          openModal({
                            modalType: 'CourseOptionsModal',
                            modalProps: {
                              course,
                            },
                          })
                        )
                    : null
                }
              >
                {course.title}
              </Header>
            )}
          </Fragment>
        )}
      </Media>
      <Main>
        <CourseDetailedHeader
          isAdmin={isAdmin}
          isFollowing={isFollowing}
          course={course}
          setEditMode={setEditMode}
          editMode={editMode}
        />
        <CourseDetailedMenu
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setPredicate={handleSetPredicate}
          predicate={predicate}
        />
        {editMode ? (
          <ButtonComponent
            width="100%"
            secondary
            color="primary"
            style={{ margin: '2rem 0' }}
            clickAction={() =>
              dispatch(
                openModal({
                  modalType: 'AssignmentFormModal',
                  modalProps: {
                    course,
                  },
                })
              )
            }
          >
            Add Assignment
          </ButtonComponent>
        ) : null}
        {isFollowing ? (
          <CourseDetailedAssignments
            isAdmin={isAdmin}
            assignments={assignments}
            course={course}
            editMode={editMode}
            loading={loading}
            getNextAssignments={handleFetchNextAssignments}
            moreAssignments={moreAssignments}
          />
        ) : (
          <CenteredDiv>
            <LockClosed />
            <Message>Follow this course to view assignments</Message>
          </CenteredDiv>
        )}
        {isAdmin && (
          <BottomRightAction>
            <CircleButton
              color={editMode ? 'black' : 'secondary'}
              icon={editMode ? 'x' : 'pencil'}
              size="3rem"
              clickAction={() => setEditMode(!editMode)}
            />
          </BottomRightAction>
        )}
      </Main>
    </Fragment>
  );
}
