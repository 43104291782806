import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  DesktopLink,
  DrawerItems,
  FlexWrapper,
  NavDrawerInfoContainer,
  StyledNavBackground,
  StyledNavDrawer,
  StyledNavLinks,
  StyledParagraph,
} from '../../app/components/NavComponents';
import { AnimatePresence, motion } from 'framer-motion';
import Burger from '../../app/components/Burger';
import { COLORS, SIZES } from '../../app/layout/constants';
import HomeIcon from '../../app/icons/solid/Home';
import ProfileIcon from '../../app/icons/solid/User';
import CollectionIcon from '../../app/icons/solid/Collection';
import GridIcon from '../../app/icons/solid/ViewGrid';
import PlusIcon from '../../app/icons/solid/Plus';
import SearchIcon from '../../app/icons/solid/Search';
import SettingsIcon from '../../app/icons/solid/Cog';
import SignOutIcon from '../../app/icons/solid/Logout';
import CloseIcon from '../../app/icons/solid/X';
import Avatar from '../../app/common/Avatar';

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 15;
`;

const NavIconStyles = {
  marginRight: SIZES.lengthSm3,
};

const iconColor = COLORS.primaryText;

export default function SignedInLinksDesktop({
  currentUser,
  currentUserProfile,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <AnimatePresence>
      {menuOpen ? (
        <Fragment>
          <StyledNavDrawer
            as={motion.div}
            animate={{ x: 0 }}
            transition={{ duration: 0.5 }}
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
          >
            <DrawerItems>
              <NavDrawerInfoContainer>
                <Avatar
                  src={currentUserProfile?.photoURL}
                  name={currentUser?.displayName}
                  size="36px"
                  small
                />
                <StyledParagraph>
                  {currentUserProfile.displayName}
                </StyledParagraph>
              </NavDrawerInfoContainer>
              <StyledNavLinks onClick={() => setMenuOpen(false)}>
                <DesktopLink to="/dash">
                  <HomeIcon color={iconColor} style={NavIconStyles} />
                  Dashboard
                </DesktopLink>
                <DesktopLink to={`/profile/${currentUser.uid}`}>
                  <ProfileIcon color={iconColor} style={NavIconStyles} />{' '}
                  Profile
                </DesktopLink>
                <DesktopLink to="/assignments">
                  <CollectionIcon color={iconColor} style={NavIconStyles} />
                  Assignments
                </DesktopLink>
                <DesktopLink to="/courses">
                  <GridIcon color={iconColor} style={NavIconStyles} />
                  Courses
                </DesktopLink>
                <DesktopLink to="/course-search">
                  <SearchIcon color={iconColor} style={NavIconStyles} /> Find
                  Course
                </DesktopLink>
                <DesktopLink to="/create-course">
                  <PlusIcon color={iconColor} style={NavIconStyles} />
                  Create Course
                </DesktopLink>
                <DesktopLink to="/settings">
                  <SettingsIcon color={iconColor} style={NavIconStyles} />
                  Settings
                </DesktopLink>
                <DesktopLink to="/signout">
                  <SignOutIcon color={iconColor} style={NavIconStyles} /> Sign
                  Out
                </DesktopLink>
              </StyledNavLinks>
            </DrawerItems>
            <StyledCloseIcon
              width="28px"
              height="28px"
              color={COLORS.primaryText}
              onClick={() => setMenuOpen(false)}
            />
          </StyledNavDrawer>

          <StyledNavBackground onClick={() => setMenuOpen(false)} />
        </Fragment>
      ) : (
        <FlexWrapper>
          <Burger action={() => setMenuOpen(true)} />
        </FlexWrapper>
      )}
    </AnimatePresence>
  );
}
