import styled from 'styled-components';
import { COLORS } from '../layout/constants';

export const BreakLineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 3px;
  background-color: ${COLORS.border};
  margin: 0.5rem 0;
`;

export const BreakLineVertical = styled.div`
  width: 2px;
  height: 3rem;
  border-radius: 3px;
  background-color: ${COLORS.border};
  margin: 0;
`;
