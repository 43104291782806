export const CREATE_COURSE = 'CREATE_COURSE';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const FETCH_COURSES = 'FETCH_COURSES';
export const DELETE_COURSE = 'DELETE_COURSE';
export const ADD_ASSIGNMENT_TO_COURSE = 'ADD_ASSIGNMENT_TO_COURSE';
export const LISTEN_TO_SELECTED_COURSE = 'LISTEN_TO_SELECTED_COURSE';
export const LISTEN_TO_COURSE_ASSIGNMENTS = 'LISTEN_TO_COURSE_ASSIGNMENTS';
export const CLEAR_SELECTED_COURSE = 'CLEAR_SELECTED_COURSE';
export const FETCH_COURSE_ASSIGNMENTS = 'FETCH_COURSE_ASSIGNMENTS';
export const CLEAR_COURSE_ASSIGNMENTS = 'CLEAR_COURSE_ASSIGNMENTS';
export const FETCH_COURSE = 'FETCH_COURSE';
