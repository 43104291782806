import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { COLORS } from '../../layout/constants';
import { closeModal } from './modalReducer';
import CloseIcon from '../../icons/outline/X';

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.5;
  z-index: 50;
`;

const Modal = styled.div`
  z-index: 51;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 80%;
  margin: auto;
  background: ${(p) => p.theme.colors.background};
  width: ${(p) => p.width || 'auto'};
  height: ${(p) => p.height || 'auto'};
  max-width: 95vw;
  padding: 1.35rem 1.25rem;
  border-radius: 12px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid ${COLORS.border};
  @media (min-width: 599px) {
    max-width: 25rem;
    min-width: 25rem;
    margin: auto;
    margin-bottom: 2rem;
  }
`;

const ModalHeader = styled.h2`
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 1.25rem;
  margin: 1rem 0;
`;

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ModalContent = styled.main`
  display: block;
  width: 100%;
`;

export default function ModalWrapper({ children, header, width }) {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Modal width={width}>
        <StyledCloseIcon
          onClick={() => dispatch(closeModal())}
          color={COLORS.primaryText}
        />
        {header && <ModalHeader>{header}</ModalHeader>}
        <ModalContent width={width}>{children}</ModalContent>
      </Modal>
      <ModalBackground onClick={() => dispatch(closeModal())} />
    </Fragment>
  );
}
