export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT';
export const DELETE_ASSIGNMENT = 'DELETE_ASSIGNMENT';
export const FETCH_ASSIGNMENTS = 'FETCH_ASSIGNMENTS';
export const LISTEN_TO_TODAYS_ASSIGNMENTS = 'LISTEN_TO_TODAYS_ASSIGNMENTS';
export const LISTEN_TO_TOMORROWS_ASSIGNMENTS =
    'LISTEN_TO_TOMORROWS_ASSIGNMENTS';
export const LISTEN_TO_OVERDUE_ASSIGNMENTS = 'LISTEN_TO_OVERDUE_ASSIGNMENTS';
export const LISTEN_TO_IMPORTANT_ASSIGNMENTS =
    'LISTEN_TO_IMPORTANT_ASSIGNMENTS';
export const LISTEN_TO_SELECTED_ASSIGNMENT = 'LISTEN_TO_SELECTED_ASSIGNMENT';
export const CLEAR_SELECTED_ASSIGNMENT = 'CLEAR_SELECTED_ASSIGNMENT';
export const CLEAR_ASSIGNMENTS = 'CLEAR_ASSIGNMENTS';
