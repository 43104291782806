import React from 'react';
import { useSelector } from 'react-redux';
import { PageTitleCenter } from '../../components/Titles';
import { Main } from '../../components/Wrappers';
import ButtonComponent from '../buttons/ButtonComponent';
import StyledLink from '../../components/StyledLink';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;

export default function ErrorComponent() {
  const { error } = useSelector((state) => state.async);
  return (
    <Main>
      <ErrorWrapper>
        <PageTitleCenter>
          {error?.message || 'Oops – we have an error'}
        </PageTitleCenter>
        <StyledLink to="/dash">
          <ButtonComponent color="black" primary>
            Return to dashboard
          </ButtonComponent>
        </StyledLink>
      </ErrorWrapper>
    </Main>
  );
}
