import styled, { css } from 'styled-components';

export const CourseHeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  min-width: 0;
  min-height: 0;
`;

export const CourseHeaderTextWrapper = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2 1 auto;
  margin-left: 1rem;
`;

export const CourseSection = styled.h3`
  font-size: 1.25rem;
  color: ${(p) => p.theme.colors.primaryText};
  margin: 0;
`;

export const CourseInfo = styled.h4`
  color: ${(p) => p.theme.colors.secondaryText};
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  margin: 0;
`;
export const ProfessorEmail = styled.h5`
  color: ${(p) => p.theme.colors.secondaryText};
  font-weight: 400;
  margin: 0;
`;

export const CourseInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CourseTitle = styled.h3`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.primaryText};
  margin: 0;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

export const moreInfoStyles = css`
  max-height: fit-content;
  margin-bottom: 1rem;
`;

export const Description = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  color: ${(p) => p.theme.colors.primaryText};
  max-height: 80px;
  margin: 0;
  /* margin-bottom: 1rem; */

  ${(p) => p.moreInfo && moreInfoStyles}
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  margin: 2rem 0;
`;

export const MoreInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const MoreInfoText = styled.h5`
  color: ${(p) => p.theme.colors.secondaryText};
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 2rem;
`;
