export const COLORS = {
    primary: '#CE2D4F',
    secondary: '#094074',
    colorGray: '#f2f5f5',

    primaryLight: '#f4cdd5',
    secondaryLight: '#e7f3fd',
    // secondaryLight: '#c6e1fb',

    colorDisabled: '#E1dfe9',

    primaryText: '#3A3335',
    secondaryText: '#9da4ba',
    tertiaryText: '#b4bdd7',

    secondaryTextSoft: '#e7e9ee',
    primaryTextSoft: '#d1d1d1',

    background: '#f9f9f9',
    gradientStart: '#eeeff3',
    gradientEnd: '#e6e6ee',

    success: '#37bc64',
    successSoft: '#E2f8E9',

    error: '#DE5050',
    errorSoft: '#FFF3F3',

    warning: '#F9AE3F',
    warningSoft: '#FFF8DE',

    border: '#dbdbdb',

    white: '#fff',
};

export const SIZES = {
    navBottomHeight: '5rem',
    HeaderBarHeight: '3rem',

    lengthSm1: '0.25rem',
    lengthSm2: '0.375rem',
    lengthSm3: '0.5rem',
    lengthMd1: '1rem',
    lengthMd2: '1.25rem',
    lengthMd3: '1.5rem',
    lengthLg1: '2rem',
    lengthLg2: '3rem',
    lengthLg3: '4rem',
};

export const SHADOW = {
    neumorphic: `12px 12px 24px 0 rgba(0, 0, 0, 0.25),
    -12px -12px 24px 0 rgba(255, 255, 255, 1)`,
    normal: `0px 6px 24px 0 rgba(0, 0, 0, 0.1);`,
};

export const MISC = {
    marginBottom: '3rem',
};

export const GLOBAL_MEDIA_QUERIES = {
    small: '(max-width: 599px)',
    medium: '(min-width: 600px) and (max-width: 1199px)',
    large: '(min-width: 1200px)',
};

export const ACCOUNT_TYPES = ['Student', 'Professor'];

export const ASSIGNMENT_TYPES = [
    'Homework',
    'Exam',
    'Project',
    'Essay',
    'Quiz',
    'Lab',
];
