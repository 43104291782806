import {
    CLEAR_ASSIGNMENTS,
    CLEAR_SELECTED_ASSIGNMENT,
    CREATE_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    LISTEN_TO_OVERDUE_ASSIGNMENTS,
    LISTEN_TO_SELECTED_ASSIGNMENT,
    LISTEN_TO_TODAYS_ASSIGNMENTS,
    LISTEN_TO_TOMORROWS_ASSIGNMENTS,
    FETCH_ASSIGNMENTS,
    UPDATE_ASSIGNMENT,
    LISTEN_TO_IMPORTANT_ASSIGNMENTS,
} from './assignmentConstants';

const initialState = {
    assignments: [],
    selectedAssignment: null,
    todaysAssignments: [],
    tomorrowsAssignments: [],
    overdueAssignments: [],
    importantAssignments: [],
    moreAssignments: true,
};

export default function assignmentReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        case CREATE_ASSIGNMENT:
            return {
                assignments: [...state.assignments, payload],
            };
        case UPDATE_ASSIGNMENT:
            return {
                assignments: [
                    ...state.assignments.filter((a) => a.id !== payload.id),
                    payload,
                ],
            };
        case DELETE_ASSIGNMENT:
            return {
                assignments: [
                    ...state.assignments.filter((a) => a.id !== payload),
                ],
            };
        case FETCH_ASSIGNMENTS:
            return {
                ...state,
                assignments: [...state.assignments, ...payload.assignments],
                moreAssignments: payload.moreAssignments,
            };
        case LISTEN_TO_TODAYS_ASSIGNMENTS:
            return {
                ...state,
                todaysAssignments: [...payload],
            };
        case LISTEN_TO_TOMORROWS_ASSIGNMENTS:
            return {
                ...state,
                tomorrowsAssignments: [...payload],
            };
        case LISTEN_TO_OVERDUE_ASSIGNMENTS:
            return {
                ...state,
                overdueAssignments: [...payload],
            };
        case LISTEN_TO_IMPORTANT_ASSIGNMENTS:
            return {
                ...state,
                importantAssignments: [...payload],
            };
        case LISTEN_TO_SELECTED_ASSIGNMENT:
            return {
                ...state,
                selectedAssignment: payload,
            };
        case CLEAR_SELECTED_ASSIGNMENT:
            return {
                ...state,
                selectedAssignment: null,
            };
        case CLEAR_ASSIGNMENTS:
            return {
                ...state,
                assignments: [],
                moreAssignments: true,
            };
        default:
            return state;
    }
}
