import React from 'react';
import { PageTitle } from '../../../app/components/Titles';
import { MainNoTop } from '../../../app/components/Wrappers';
import algoliasearch from 'algoliasearch';
import { Configure, InstantSearch } from 'react-instantsearch-core';
import { SearchBox, Stats, InfiniteHits } from 'react-instantsearch-dom';
import CourseSearchItem from './CourseSearchItem';
import styled from 'styled-components';
import { COLORS, SHADOW } from '../../../app/layout/constants';
import 'instantsearch.css/themes/reset.css';
import { Message } from '../../../app/components/Text';
import ButtonComponent from '../../../app/common/buttons/ButtonComponent';

const StyledSearchBox = styled(SearchBox)`
  /* margin-bottom: 3rem; */
  font-size: 1rem;
  font-family: inherit;
  .ais-SearchBox-form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${COLORS.white};
    box-shadow: ${SHADOW.normal};
    /* border: 1px solid ${(p) => p.theme.colors.border}; */
    border-radius: 8px;
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .ais-SearchBox-input {
    border: none;
    width: 100%;
    padding: 1rem 1.25rem;
    border-radius: 8px;
    background-color: ${COLORS.white};
    color: ${(p) => p.theme.colors.primaryText};
    font-size: 1rem;
    font-weight: 500;

    &:focus,
    &:active {
      outline: none;
    }
    &::placeholder {
      color: ${COLORS.secondaryText};
      font-weight: 400;
      font-size: 1rem;
    }
  }
  .ais-SearchBox-submit {
    border: none;
    margin-right: 1rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  .ais-SearchBox-reset {
    border: none;
    margin-right: 1rem;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const StyledHits = styled(InfiniteHits)`
  ul {
    list-style-type: none;
  }
  .ais-InfiniteHits-loadMore {
    font-weight: 600;
    text-align: center;
    width: 100%;
    color: ${(p) => p.theme.colors.secondary};
  }
`;

export default function CourseSearch() {
  const searchClient = algoliasearch(
    '4NZ0PN2FJB',
    '121624ebe2f981cea1404dbbffc3260d'
  );

  return (
    <MainNoTop>
      <PageTitle>Search</PageTitle>
      <InstantSearch searchClient={searchClient} indexName="course_search">
        <Configure hitsPerPage={4} analytics />
        <StyledSearchBox autoFocus showLoadingIndicator />
        <Message>Don't see your course?</Message>
        <ButtonComponent
          width="100%"
          link="/create-course"
          secondary
          color="primary"
        >
          Create It
        </ButtonComponent>
        <Stats />
        <StyledHits hitComponent={CourseSearchItem} />
      </InstantSearch>
    </MainNoTop>
  );
}
