import { toast } from 'react-toastify';
import { logEvent } from '../config/analytics';
import firebase from '../config/firebase';
import { setUserProfileData } from './profileService';

const db = firebase.firestore();

export function signInWithEmail(creds) {
    logEvent('login');
    return firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);
}

export function signOutFirebase() {
    return firebase.auth().signOut();
}

export async function registerInFirebase(creds) {
    try {
        const result = await firebase
            .auth()
            .createUserWithEmailAndPassword(creds.email, creds.password);
        await result.user.updateProfile({ displayName: creds.displayName });
        logEvent('sign_up');
        return await setUserProfileData(result.user);
    } catch (error) {
        throw error;
    }
}

export function updateUserPassword(creds) {
    const user = firebase.auth().currentUser;
    return user.updatePassword(creds.newPassword1);
}

export function sendResetPassword(email) {
    try {
        return firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
        throw error;
    }
}

export async function updateUserEmail(creds) {
    const user = firebase.auth().currentUser;
    return user.updateEmail(creds.email).then(async function () {
        db.collection('users').doc(user.uid).update({
            email: creds.email,
        });
    });
}

export async function googleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    try {
        const result = await firebase.auth().signInWithPopup(provider);
        if (result.additionalUserInfo.isNewUser) {
            await setUserProfileData(result.user);
            logEvent('sign_up');
        } else {
            logEvent('login');
        }
    } catch (error) {
        toast.error(error.message);
    }
}
