import styled from 'styled-components';
import { COLORS } from '../layout/constants';

export const StudentName = styled.h3`
  font-size: 1.25rem;
  color: ${COLORS.primaryText};
  margin: 0;
`;

export const SchoolName = styled.h4`
  color: ${COLORS.secondaryText};
  font-weight: 500;
  margin: 0;
`;

export const ProfileHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
`;

export const ProfileAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  flex: 1;
`;

export const ProfileHeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 2 1 auto;
  margin-bottom: 1rem;
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 16px;
  flex: 2 1 auto;
  /* margin-bottom: 2rem; */
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfoNumber = styled.h2`
  color: ${COLORS.primaryText};
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
`;

export const InfoHeader = styled.h3`
  color: ${COLORS.primaryText};
  font-size: 0.75rem;
  margin: 0;
  font-weight: 600;
`;
