import React, { Fragment } from 'react';
import DropDownInput from '../../app/common/inputs/DropDownInput';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import { InputGroup } from '../../app/components/Wrappers';
import TextArea from '../../app/common/inputs/TextArea';
import SingleDateInput from '../../app/common/inputs/SingleDateInput';
import TimeInput from '../../app/common/inputs/TimeInput';
import { ASSIGNMENT_TYPES } from '../../app/layout/constants';

export default function AssignmentFormInputs({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) {
  return (
    <Fragment>
      <LoginTextInput
        name="title"
        label="Title"
        placeholder="Chapter 11.3 Problems"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        error={errors.title && touched.title ? errors.title : null}
      />
      <InputGroup>
        <SingleDateInput name="dueDay" label="Due Date" value={values.dueDay} />
        <TimeInput
          name="dueTime"
          onChange={handleChange}
          label="Time"
          placeholder="11:59pm"
          value={values.dueTime}
        />
      </InputGroup>
      <DropDownInput
        name="type"
        label="Type"
        placeholder="Homework"
        onChange={handleChange}
        options={ASSIGNMENT_TYPES}
        onBlur={handleBlur}
        value={values.type}
      />

      <TextArea
        name="description"
        label="Description"
        placeholder="(Optional)"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.description}
      />
    </Fragment>
  );
}
