import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import {
  NavMenuMobile,
  NavMenuDesktop,
} from '../../app/components/NavComponents';
import Media from 'react-media';
import { GLOBAL_MEDIA_QUERIES } from '../../app/layout/constants';
import SignedInLinksMobile from './SignedInLinksMobile';
import SignedInLinksDesktop from './SignedInLinksDesktop';
import { useHistory } from 'react-router';
import Logo from '../../app/common/Logo';
import SignedOutLinksDesktop from './SignedOutLinksDesktop';

export default function NavBar() {
  const { currentUser, authenticated } = useSelector((state) => state.auth);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const history = useHistory();
  return (
    <Fragment>
      <Media queries={GLOBAL_MEDIA_QUERIES}>
        {(matches) => (
          <Fragment>
            {matches.small && authenticated ? (
              <NavMenuMobile>
                <SignedInLinksMobile
                  currentUserProfile={currentUserProfile}
                  currentUser={currentUser}
                />
              </NavMenuMobile>
            ) : null}
            {(matches.medium || matches.large) && (
              <NavMenuDesktop>
                <Logo
                  onClick={() =>
                    authenticated ? history.push('/dash') : history.push('/')
                  }
                />

                {authenticated ? (
                  <SignedInLinksDesktop
                    currentUserProfile={currentUserProfile}
                    currentUser={currentUser}
                  />
                ) : (
                  <SignedOutLinksDesktop />
                )}
              </NavMenuDesktop>
            )}
          </Fragment>
        )}
      </Media>
    </Fragment>
  );
}
