import React from 'react';
import TextInput from '../../app/common/inputs/TextInput';
import styled from 'styled-components';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import TextArea from '../../app/common/inputs/TextArea';
import { toast } from 'react-toastify';
import SingleDateInput from '../../app/common/inputs/SingleDateInput';
import Header from '../../app/common/Header';
import { useHistory, useLocation } from 'react-router';
import { Main } from '../../app/components/Wrappers';
import {
  addAssignmentToCourseInFirestore,
  updateAssignmentInCourseInFirestore,
} from '../../app/firestore/courseService';
import DropDownInput from '../../app/common/inputs/DropDownInput';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../app/common/LoadingComponent';
import useFirestoreDoc from '../../app/hooks/useFirestoreDoc';
import { listenToSelectedAssignmentFromFirestore } from '../../app/firestore/assignmentService';
import { listenToSelectedAssignment } from '../assignments/assignmentActions';
import { openModal } from '../../app/common/modals/modalReducer';

const CourseForm = styled.form`
  width: 100%;
  margin-bottom: 3rem;
  margin-top: 5rem;
`;

export default function CreateAssignment({ match }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { course } = location.state;
  const { selectedAssignment } = useSelector((state) => state.assignment);
  const { loading } = useSelector((state) => state.async);

  const shouldExecute =
    location.pathname === `/manage-assignment/${match.params.id}`;

  let initialValues = {
    title: '',
    dueDate: new Date(),
    type: 'homework',
    description: '',
  };

  if (selectedAssignment) {
    initialValues = {
      title: selectedAssignment.title,
      dueDate: selectedAssignment.dueDate,
      type: selectedAssignment.type,
      description: selectedAssignment.description,
    };
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    dueDate: Yup.string().required(),
    type: Yup.string().required(),
    description: Yup.string().notRequired(),
  });

  useFirestoreDoc({
    shouldExecute: !!shouldExecute,
    query: () => listenToSelectedAssignmentFromFirestore(match.params.id),
    data: (assignment) => dispatch(listenToSelectedAssignment(assignment)),
    deps: [dispatch, match.params.id],
  });

  if (loading) return <LoadingComponent />;

  return (
    <Main>
      <Header showBackBtn>
        {selectedAssignment ? 'Edit Assignment' : 'Add Assignment'}
      </Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (selectedAssignment) {
              console.log('updating assignment');
              await updateAssignmentInCourseInFirestore(
                course,
                values,
                selectedAssignment.id
              );
              history.push(`/assignments/${selectedAssignment.id}`);
            } else {
              await addAssignmentToCourseInFirestore(course, values);
              history.push(`/courses/${match.params.id}`);
            }
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          errors,
          touched
        }) => (
          <CourseForm onSubmit={handleSubmit}>
            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            <TextInput
              name="title"
              label="Title"
              placeholder="Chapter 11.3 Problems"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={errors.title && touched.title ? errors.title : null}
            />
            <SingleDateInput
              name="dueDate"
              label="Due Date"
              placeholder=""
              id={values.dueDate}
              onBlur={handleBlur}
              error={errors.dueDate && touched.dueDate ? errors.dueDate : null}
            />
            <DropDownInput
              name="type"
              label="Type"
              placeholder="Homework"
              onChange={handleChange}
              options={['Homework', 'Exam', 'Project', 'Essay']}
              onBlur={handleBlur}
              value={values.type}
            />
            <TextArea
              name="description"
              label="Description"
              placeholder="(Optional)"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
            />
            <ButtonComponent
              style={{ marginTop: '3rem' }}
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              primary
              color="secondary"
              type="submit"
              width="100%"
            >
              {selectedAssignment ? 'Update' : 'Create'}
            </ButtonComponent>
            {selectedAssignment && (
              <ButtonComponent
                color="error"
                width="100%"
                clickAction={() =>
                  dispatch(
                    openModal({
                      modalType: 'ConfirmAssignmentDelete',
                      modalProps: { assignment: selectedAssignment, history },
                    })
                  )
                }
              >
                Delete Assignment
              </ButtonComponent>
            )}
          </CourseForm>
        )}
      </Formik>
    </Main>
  );
}
