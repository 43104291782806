import React from 'react';
import { Fragment } from 'react';
import Avatar from '../../app/common/Avatar';
import {
  InfoHeader,
  InfoNumber,
  InfoWrapper,
  ProfileAvatarWrapper,
  ProfileHeaderTextWrapper,
  ProfileHeaderWrapper,
  ProfileInfoWrapper,
  SchoolName,
  StudentName,
} from '../../app/components/ProfileComponents';
import { BreakLineHorizontal } from '../../app/components/Lines';

export default function ProfileHeader({ profile, isCurrentUser }) {
  return (
    <Fragment>
      <ProfileHeaderWrapper>
        <ProfileAvatarWrapper>
          <Avatar
            src={profile.photoURL || null}
            size="70px"
            name={profile.displayName}
          />
        </ProfileAvatarWrapper>
        <ProfileInfoWrapper>
          <InfoWrapper>
            <InfoNumber>{profile.studyScore.toFixed(0)}</InfoNumber>
            <InfoHeader>Study Score</InfoHeader>
          </InfoWrapper>
          <InfoWrapper>
            <InfoNumber>{profile.courses.length}</InfoNumber>
            <InfoHeader>Enrolled</InfoHeader>
          </InfoWrapper>
          <InfoWrapper>
            <InfoNumber>{profile.createdCourses.length}</InfoNumber>
            <InfoHeader>Created</InfoHeader>
          </InfoWrapper>
        </ProfileInfoWrapper>
      </ProfileHeaderWrapper>
      <ProfileHeaderTextWrapper>
        <StudentName>{profile.accountType}</StudentName>
        <SchoolName>{profile.school || ''}</SchoolName>
      </ProfileHeaderTextWrapper>
      <BreakLineHorizontal style={{ marginBottom: '2rem' }} />
    </Fragment>
  );
}
