import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../layout/constants';

const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  max-width: 10rem;
`;

const Select = styled.select`
  border: none;
  padding: 0.5rem;
  font-weight: 600;
  flex: 3;
  color: ${(p) => p.theme.colors.primaryText};
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid ${(p) => p.theme.colors.primaryText};
  border-radius: 8px;
  width: ${(p) => p.width || '100%'};
  background-color: ${(p) => p.theme.colors.white};
  width: 100%;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${(p) => p.theme.colors.primaryText} 50%
    ),
    linear-gradient(
      135deg,
      ${(p) => p.theme.colors.primaryText} 50%,
      transparent 50%
    );
  background-position: calc(100% - 20px) calc(0.8em + 2px),
    calc(100% - 15px) calc(0.8em + 2px);
  background-size: 5px 5px, 5px 5px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    border: 2px solid ${(p) => p.theme.colors.primary};
    background-image: linear-gradient(
        45deg,
        ${(p) => p.theme.colors.primary} 50%,
        transparent 50%
      ),
      linear-gradient(
        135deg,
        transparent 50%,
        ${(p) => p.theme.colors.primary} 50%
      );
    background-position: calc(100% - 15px) 0.8em, calc(100% - 20px) 0.8em;
    background-size: 5px 5px, 5px 5px;
  }
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${COLORS.secondaryText};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export default function SelectInput(props) {
  return (
    <Form onChange={props.handleChange}>
      <Select placeholder={props.placeholder} type="text">
        {props.children}
      </Select>
    </Form>
  );
}
