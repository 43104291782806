import React from 'react';
import { useDispatch } from 'react-redux';
import ButtonComponent from '../buttons/ButtonComponent';
import { closeModal, openModal } from './modalReducer';
import ModalWrapper from './ModalWrapper';

export default function CourseOptionsModal({ course }) {
  const dispatch = useDispatch();
  return (
    <ModalWrapper
      style={{ justifyContent: 'space-between' }}
      header="Course Options"
    >
      <ButtonComponent
        color="secondary"
        primary
        width="100%"
        clickAction={() =>
          dispatch(
            openModal({
              modalType: 'AssignmentFormModal',
              modalProps: {
                course,
              },
            })
          )
        }
      >
        Add Assignment
      </ButtonComponent>
      <ButtonComponent
        clickAction={() => dispatch(closeModal())}
        link={`/manage/${course.id}`}
        color="secondary"
        secondary
        width="100%"
      >
        Edit Details
      </ButtonComponent>
      <ButtonComponent
        width="100%"
        clickAction={() => dispatch(closeModal())}
        secondary
      >
        Cancel
      </ButtonComponent>
    </ModalWrapper>
  );
}
