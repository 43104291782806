import React, { Fragment } from 'react';
import { Route, Switch, useLocation } from 'react-router';
import CourseDetailed from '../../features/courses/CourseDetailed/CourseDetailed';
import CourseSearch from '../../features/courses/CourseSearch/CourseSearch';
import UserCourses from '../../features/courses/UserCourses';
import Dashboard from '../../features/dashboard/Dashboard';
import UserAssignments from '../../features/assignments/UserAssignments';
import NavBar from '../../features/nav/NavBar';
import './style.css';
import { AppWrapper } from '../components/Wrappers';
import WelcomePage from '../../features/nav/WelcomePage';
import UserProfile from '../../features/profile/UserProfile';
import { ThemeProvider } from 'styled-components';
import { COLORS } from './constants';
import { ToastContainer } from 'react-toastify';
import Login from '../../features/auth/Login';
import SignUp from '../../features/auth/SignUp';
import CourseForm from '../../features/create/CourseForm';
import CreateAssignment from '../../features/create/CreateAssignment';
import { useSelector } from 'react-redux';
import LoadingComponent from '../common/LoadingComponent';
import Settings from '../../features/settings/Settings';
import ErrorComponent from '../common/errors/ErrorComponent';
import AssignmentDetailed from '../../features/assignments/AssignmentDetailed/AssignmentDetailed';
import ModalManager from '../common/modals/ModalManager';
import ChangePassword from '../../features/settings/ChangePassword';
import ChangeEmail from '../../features/settings/ChangeEmail';
import ChangeName from '../../features/settings/ChangeName';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import ResetPassword from '../../features/auth/ResetPassword';
import { AnimatePresence } from 'framer-motion';
import SignOut from '../../features/auth/SignOut';

function App() {
  const { key } = useLocation();
  const location = useLocation();
  const { initialized } = useSelector((state) => state.async);

  if (!initialized) return <LoadingComponent content="Welcome" />;

  return (
    <Fragment>
      <ThemeProvider theme={{ colors: COLORS }}>
        <ModalManager />
        <ToastContainer position="top-center" hideProgressBar />
        <Route path="/" exact component={WelcomePage} />
        <NavBar />
        <AppWrapper>
          <AnimatePresence>
            <Switch location={location} key={key}>
              <Route path="/dash" component={Dashboard} />
              <Route path="/courses" exact component={UserCourses} />
              <Route path="/courses/:id" exact component={CourseDetailed} />
              <Route path="/course-search" component={CourseSearch} />
              <Route
                path={['/create-course', '/manage/:id']}
                component={CourseForm}
              />
              <Route path="/profile/:id" component={UserProfile} />
              <Route path="/assignments" exact component={UserAssignments} />
              <Route path="/assignments/:id" component={AssignmentDetailed} />
              <Route
                path={[
                  '/courses/:id/create-assignment/',
                  '/manage-assignment/:id',
                ]}
                component={CreateAssignment}
              />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />
              <Route path="/signout" component={SignOut} />
              <Route path="/settings" component={Settings} />
              <Route path="/change-password" component={ChangePassword} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/change-email" component={ChangeEmail} />
              <Route path="/change-name" component={ChangeName} />
              <Route path="/error" component={ErrorComponent} />
            </Switch>
          </AnimatePresence>
        </AppWrapper>
      </ThemeProvider>
    </Fragment>
  );
}

export default App;
