import React from 'react';
import styled from 'styled-components';
import StyledLink from '../../app/components/StyledLink';
import Arrow from '../../app/icons/solid/ChevronRight';

const SettingsItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colors.border};
  padding: 0.75rem 0;
`;

const Setting = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  color: ${(p) => p.theme.colors.primaryText};
  padding: 0;
  margin: 0;
`;

const Value = styled.h3`
  font-size: 1rem;
  font-weight: 500;
  color: ${(p) => p.theme.colors.secondaryText};
  padding: 0;
  margin: 0;
`;

export default function SettingsItem({ setting, value, action, link }) {
  let settingComponent = (
    <SettingsItemContainer onClick={action}>
      <Setting>{setting}</Setting>
      {value && <Value>{value}</Value>}
      {!value && <Arrow />}
    </SettingsItemContainer>
  );

  let renderedSetting = settingComponent;

  if (link) {
    renderedSetting = (
      <StyledLink width="100%" button to={link}>
        {settingComponent}
      </StyledLink>
    );
  }

  return renderedSetting;
}
