import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Header from '../../app/common/Header';
import { Main } from '../../app/components/Wrappers';
import { handleSignOut } from '../../app/util/authUtil';
import SettingsItem from './SettingsItem';
import SettingsSection from './SettingsSection';
import Media from 'react-media';
import { GLOBAL_MEDIA_QUERIES } from '../../app/layout/constants';

export default function Settings() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const history = useHistory();
  return (
    <Fragment>
      <Media queries={GLOBAL_MEDIA_QUERIES}>
        {(matches) => (
          <Fragment>
            {matches.small && <Header showBackBtn>Settings</Header>}
          </Fragment>
        )}
      </Media>

      <Main>
        <SettingsSection title="Profile">
          <SettingsItem setting="Email" value={currentUserProfile?.email} />
          <SettingsItem
            setting="Name"
            value={currentUserProfile?.displayName}
          />
          <SettingsItem setting="Change Name" link="/change-name" />
        </SettingsSection>
        <SettingsSection title="Security">
          <SettingsItem setting="Change Email" link="/change-email" />
          <SettingsItem setting="Change Password" link="/change-password" />
        </SettingsSection>
        <SettingsSection title="Course">
          <SettingsItem setting="Create Course" link="/create-course" />
        </SettingsSection>
        <SettingsSection>
          <SettingsItem
            setting="Sign Out"
            action={() => handleSignOut(history)}
          />
        </SettingsSection>
      </Main>
    </Fragment>
  );
}
