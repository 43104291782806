import React from 'react';
import styled, { css } from 'styled-components';

const activeStyles = css`
  background: #262526;
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: 50%;
    background: linear-gradient(45deg, #21fce2, #0b8fe0);
  }
`;

const smallStyles = css`
  font-size: 16px;
`;

const outlineStyles = css`
  /* background: #262526; */
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    border-radius: 50%;
    /* background: linear-gradient(45deg, #21fce2, #0b8fe0); */
    background: ${(p) => p.theme.colors.primary};
  }
`;

const StyledWrapper = styled.span`
  /* display: block; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${(p) => p.size};
  height: ${(p) => p.size};
  padding: 5px;
  border-radius: 50%;
  ${(p) => p.isActive && activeStyles}
  ${(p) => p.outline && outlineStyles}
`;

const avatarStyles = css`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.tertiaryText};
  color: white;
`;

const StyledImage = styled.img`
  ${avatarStyles};
  object-fit: cover;
`;

const StyledSpan = styled.span`
  ${avatarStyles};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  ${(p) => p.small && smallStyles}
`;

const StyledBadge = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  top: -6px;
  right: -2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  /* border: 4px solid #262526; */
  background: #21fc6b;
`;

export default function Avatar({
  size,
  src,
  isActive,
  hasBadge,
  outline,
  name,
  small,
}) {
  let initials;
  if (name) {
    initials = name.split(' ');
    if (initials.length > 1) {
      initials = initials.shift().charAt(0) + initials.pop().charAt(0);
    } else {
      initials = name.substring(0, 2);
    }
  }

  return (
    <StyledWrapper outline={outline} size={size} isActive={isActive}>
      {hasBadge && <StyledBadge />}
      {src ? (
        <StyledImage alt={name} src={src} />
      ) : (
        <StyledSpan small={small}>{initials}</StyledSpan>
      )}
    </StyledWrapper>
  );
}
