import React from 'react';
import { Fragment } from 'react';
import DateRangeInput from '../../app/common/inputs/DateRangeInput';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import TextInput from '../../app/common/inputs/TextInput';
import { InputGroup } from '../../app/components/Wrappers';
import TextArea from '../../app/common/inputs/TextArea';

export default function CourseFormInputs({
  handleChange,
  handleBlur,
  values,
  errors,
  touched
}) {
  return (
    <Fragment>
      <LoginTextInput
        name="title"
        label="Title"
        placeholder="Physics in the Modern World"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.title}
        error={errors.title && touched.title ? errors.title : null}
      />
      <InputGroup>
        <LoginTextInput
          style={{ marginRight: '1rem' }}
          name="sectionTitle"
          label="Section"
          placeholder="PHY-252 (003)"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.sectionTitle}
          error={
            errors.sectionTitle && touched.sectionTitle
              ? errors.sectionTitle
              : null
          }
        />
        <LoginTextInput
          name="school"
          label="School"
          placeholder="John's University"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.school}
          error={errors.school && touched.school ? errors.school : null}
        />
      </InputGroup>
      <InputGroup>
        <LoginTextInput
          style={{ marginRight: '1rem' }}
          name="professorName"
          label="Professor"
          placeholder="Dr. John Johnson"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.professorName}
          error={
            errors.professorName && touched.professorName
              ? errors.professorName
              : null
          }
        />
        <LoginTextInput
          name="professorEmail"
          label="Professor's Email"
          placeholder="john@school.edu"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.professorEmail}
          error={
            errors.professorEmail && touched.professorEmail
              ? errors.professorEmail
              : null
          }
        />
      </InputGroup>
      <DateRangeInput
        name="dates"
        label="Semester length"
        startDate={values.startDate}
        startDateId="courseStartDate"
        endDate={values.endDate}
        endDateId="courseEndDate"
      />
      <TextInput
        name="meetingTime"
        label="Class Time"
        placeholder="M W F 8:00am - 9:30am"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.meetingTime}
        error={
          errors.meetingTime && touched.meetingTime ? errors.meetingTime : null
        }
      />
      {/* <ClassTimeInput value={values.meetingTime} name="meetingTimes" /> */}
      <TextArea
        name="description"
        label="Description"
        placeholder="(Optional)"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.description}
        error={
          errors.description && touched.description ? errors.description : null
        }
      />
    </Fragment>
  );
}
