import React, { useState, useEffect, Fragment } from 'react';
import UserAssignmentsList from './UserAssignmentsList';
import { MainNoTop } from '../../app/components/Wrappers';
import { PageTitle } from '../../app/components/Titles';
import { useDispatch, useSelector } from 'react-redux';
import { clearAssignments, fetchAssignments } from './assignmentActions';
import AssignmentsPageControls from './AssignmentsPageControls';
import NoAssignmentsMessage from './NoAssignmentsMessage';
import { motion } from 'framer-motion';
import LoadingSpinnerCentered from '../../app/components/LoadingSpinnerCentered';
import { Redirect } from 'react-router';

export default function UserAssignments() {
  const limit = 8;
  const dispatch = useDispatch();
  const { assignments, moreAssignments } = useSelector(
    (state) => state.assignment
  );
  const { loading, error } = useSelector((state) => state.async);
  const [predicate, setPredicate] = useState(new Map([['filter', 'upcoming']]));
  const [loadingInitial, setLoadingInitial] = useState(false);
  const [lastDocSnapshot, setLastDocSnapshot] = useState(null);

  function handleSetPredicate(key, value) {
    dispatch(clearAssignments());
    setLastDocSnapshot(null);
    setPredicate(new Map(predicate.set(key, value)));
  }

  useEffect(() => {
    setLoadingInitial(true);
    dispatch(fetchAssignments(predicate, limit)).then((lastVisible) => {
      setLastDocSnapshot(lastVisible);
      setLoadingInitial(false);
    });
    return () => {
      dispatch(clearAssignments());
    };
  }, [dispatch, predicate]);

  function handleFetchNextAssignments() {
    dispatch(fetchAssignments(predicate, limit, lastDocSnapshot)).then(
      (lastVisible) => {
        setLastDocSnapshot(lastVisible);
      }
    );
  }

  if (error) return <Redirect to="/error" />;

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    // exit: {
    //   x: '-100vw',
    //   opacity: 0,
    //   transition: {
    //     ease: 'easeInOut',
    //   },
    // },
  };

  return (
    <MainNoTop
      as={motion.main}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
      exit="exit"
    >
      <PageTitle>Assignments</PageTitle>
      <AssignmentsPageControls
        predicate={predicate}
        setPredicate={handleSetPredicate}
      />

      {loadingInitial ? (
        <LoadingSpinnerCentered />
      ) : (
        <Fragment>
          <NoAssignmentsMessage
            predicate={predicate}
            assignments={assignments}
          />
          <UserAssignmentsList
            moreAssignments={moreAssignments}
            getNextAssignments={handleFetchNextAssignments}
            assignments={assignments}
            loading={loading}
          />
        </Fragment>
      )}
    </MainNoTop>
  );
}
