import firebase from '../config/firebase';

const db = firebase.firestore();

export function getUserProfile(userId) {
    return db.collection('users').doc(userId);
}

export function setUserProfileData(user) {
    return db
        .collection('users')
        .doc(user.uid)
        .set({
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL || null,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            studyScore: 0,
            courses: [],
            createdCourses: [],
            accountType: "Student",
        });
}

export async function updateUserDisplayName(creds) {
    const user = firebase.auth().currentUser;
    return db
        .collection('users')
        .doc(user.uid)
        .update({ displayName: creds.name });
}
