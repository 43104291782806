import styled from 'styled-components';

export const Greeting = styled.h1`
  font-size: 1.75rem;
  color: ${(p) => p.theme.colors.primaryText};
  @media (min-width: 750px) {
    margin: 6rem 0 4rem 0;
  }
`;

export const Message = styled.p`
  text-align: center;
  font-weight: 500;
  color: ${(p) => p.theme.colors.secondaryText};
`;

export const ErrorMessage = styled.p`
  text-align: center;
  font-weight: 500;
  color: ${(p) => p.theme.colors.error};
`;
