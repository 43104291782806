import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../layout/constants';

export const PageTitle = styled.h2`
  color: ${COLORS.primaryText};
  font-size: 2rem;
  margin: 1rem 0;

  z-index: 9;
`;

export const PageTitleSmaller = styled.h2`
  color: ${(p) => p.theme.colors.primaryText};
`;

export const PageTitleCenter = styled.h2`
  color: ${(p) => p.theme.colors.primaryText};
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0;
`;

export const DateGroupTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  color: ${(p) => p.color || p.theme.colors.secondary};
  margin: 0;
  margin-bottom: 1rem;
`;

const CardHeadingWrapper = styled.h3`
  color: ${(p) => p.color || COLORS.primaryText};
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 5;
  margin-bottom: 0.5rem;

  @media (min-width: 750px) {
    margin: auto;
    margin-bottom: 0.5rem;
    font-size: 1.4rem;
  }
`;

export function CardHeading(props) {
  return (
    <CardHeadingWrapper color={props.color}>
      {props.children}
    </CardHeadingWrapper>
  );
}
