import styled from 'styled-components';

export const BottomSection = styled.div`
  position: fixed;
  bottom: 5rem;
  right: 1rem;
  left: 1rem;
  z-index: 10;
  @media (min-width: 599px) {
    display: none;
  }
`;
