import React, { useState, Fragment } from 'react';

import Avatar from '../../../app/common/Avatar';
import ButtonComponent from '../../../app/common/buttons/ButtonComponent';
import {
  CourseHeaderTextWrapper,
  CourseHeaderWrapper,
  CourseInfo,
  CourseInfoWrapper,
  CourseSection,
  CourseTitle,
  Description,
  MoreInfoText,
} from '../../../app/components/CourseComponents';
import {
  handleUserFollowCourse,
  handleUserUnfollowCourse,
} from '../../../app/util/courseUtil.js';
import InfoComponent from '../../../app/common/InfoComponent';
import { format } from 'date-fns';
import { Badge } from '../../../app/components/Badge';
import { GLOBAL_MEDIA_QUERIES } from '../../../app/layout/constants';
import { openModal } from '../../../app/common/modals/modalReducer';
import { useDispatch } from 'react-redux';
import Media from 'react-media';
import { motion, AnimatePresence } from 'framer-motion';

export default function CourseDetailedHeader({
  course,
  isFollowing,
  isAdmin,
  setEditMode,
  editMode,
}) {
  const [moreInfo, setMoreInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <CourseHeaderWrapper>
        <Avatar
          src={course.professorPhotoURL || null}
          name={course.professorName}
          size="70px"
          style={{ flex: 1 }}
        />
        <CourseHeaderTextWrapper>
          <CourseSection>{course.sectionTitle}</CourseSection>
          <CourseInfo>{course.professorName}</CourseInfo>
          <CourseInfo>{course.school}</CourseInfo>
        </CourseHeaderTextWrapper>
      </CourseHeaderWrapper>
      {isAdmin ? <Badge>Admin</Badge> : null}
      <Media queries={GLOBAL_MEDIA_QUERIES}>
        {(matches) => (
          <Fragment>
            {(matches.medium || matches.large) && isAdmin && (
              <ButtonComponent
                clickAction={() =>
                  dispatch(
                    openModal({
                      modalType: 'CourseOptionsModal',
                      modalProps: {
                        course,
                      },
                    })
                  )
                }
                secondary
                color="secondary"
                width="100%"
              >
                Manage Course
              </ButtonComponent>
            )}
          </Fragment>
        )}
      </Media>

      {!isAdmin && (
        <Fragment>
          {isFollowing ? (
            <ButtonComponent
              clickAction={() =>
                handleUserUnfollowCourse(setLoading, course.id)
              }
              loading={loading}
              secondary
              color="primary"
              width="100%"
            >
              Following
            </ButtonComponent>
          ) : (
            <ButtonComponent
              clickAction={() => handleUserFollowCourse(setLoading, course.id)}
              loading={loading}
              color="primary"
              primary
              width="100%"
            >
              Follow
            </ButtonComponent>
          )}
        </Fragment>
      )}
      <CourseInfoWrapper>
        <CourseTitle>{course.title}</CourseTitle>
        <Description moreInfo={moreInfo}>
          {course.description || 'No description'}
        </Description>
        {moreInfo && (
          <AnimatePresence>
            <motion.div
              initial={{ transition: { duration: 0.5 } }}
              animate={{ y: 0, x: 0 }}
            >
              <InfoComponent infoTitle="Class Time" info={course.meetingTime} />
              <InfoComponent
                infoTitle="Semester Start"
                info={format(course.startDate, 'iiii, LLLL do')}
              />
              <InfoComponent
                infoTitle="Semester End"
                info={format(course.endDate, 'iiii, LLLL do')}
              />
              <InfoComponent
                infoTitle="Professor Email"
                info={course.professorEmail}
              />
              <InfoComponent
                infoTitle="Students"
                info={course.students.length}
              />
            </motion.div>
          </AnimatePresence>
        )}
      </CourseInfoWrapper>
      {moreInfo ? (
        <MoreInfoText onClick={() => setMoreInfo(false)}>Less</MoreInfoText>
      ) : (
        <MoreInfoText onClick={() => setMoreInfo(true)}>More</MoreInfoText>
      )}
    </Fragment>
  );
}
