import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { handleSignOut } from '../../app/util/authUtil';

export default function SignOut() {
  const history = useHistory();

  useEffect(() => {
    handleSignOut(history);
  });
  return <p>signing out</p>;
}
