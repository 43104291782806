import React from 'react';
import styled from 'styled-components';
import DashboardList from './DashboardList';

const DashboardListContainer = styled.div`
  display: block;
`;

export default function DashboardListGroup({
  todaysAssignments,
  tomorrowsAssignments,
  overdueAssignments,
  importantAssignments,
}) {
  return (
    <DashboardListContainer>
      <DashboardList header="today" assignments={todaysAssignments} />
      <DashboardList header="tomorrow" assignments={tomorrowsAssignments} />
      {importantAssignments.length > 0 && (
        <DashboardList header="important" assignments={importantAssignments} />
      )}
      <DashboardList
        header="overdue"
        assignments={overdueAssignments}
      />
    </DashboardListContainer>
  );
}
