import React from 'react';
import styled from 'styled-components';

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.5rem;
`;
const InfoTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  color: ${(p) => p.theme.colors.secondaryText};
  margin: 0;
`;

const Info = styled.p`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primaryText};
  margin: 0;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 599px) {
    max-width: 500px;
  }
`;

export default function InfoComponent({ info, infoTitle }) {
  return (
    <InfoWrapper>
      <InfoTitle>{infoTitle}</InfoTitle>
      <Info>{info}</Info>
    </InfoWrapper>
  );
}
