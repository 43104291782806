import React from 'react';
import { COLORS, SHADOW } from '../../app/layout/constants';
import styled from 'styled-components';
import StyledLink from '../../app/components/StyledLink';

const CourseItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 10rem;
  height: 10rem;
  padding: 0.5rem;
  position: relative;
  /* background: linear-gradient(
    to bottom right,
    ${COLORS.gradientStart},
    ${COLORS.gradientEnd}
  ); */
  background: ${(p) => p.theme.colors.white};
  box-shadow: ${SHADOW.normal};
  padding: 1.35rem 1.25rem;
  border-radius: 12px;
  margin-bottom: ${(p) => p.bottomMargin || 0};

  @media (min-width: 599px) {
    width: 12rem;
    height: 12rem;
  }
`;

const CourseTitle = styled.h3`
  color: ${COLORS.primaryText};
  font-weight: 600;
  font-size: 1.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
`;

const CourseInfo = styled.h4`
  color: ${COLORS.secondaryText};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
`;

export default function CourseItem({ course }) {
  return (
    <StyledLink to={`/courses/${course.id}`}>
      <CourseItemContainer>
        <CourseTitle>{course.sectionTitle}</CourseTitle>
        <CourseInfo>{course.title}</CourseInfo>
      </CourseItemContainer>
    </StyledLink>
  );
}
