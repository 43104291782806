import { toast } from 'react-toastify';
import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from '../../app/async/asyncReducer';
import {
    fetchCourseAssignmentsFromUserFirestore,
    fetchCourseFromFirestore,
} from '../../app/firestore/courseService';
import { dataFromSnapshot } from '../../app/firestore/firestoreService';
import {
    ADD_ASSIGNMENT_TO_COURSE,
    CREATE_COURSE,
    DELETE_COURSE,
    FETCH_COURSES,
    UPDATE_COURSE,
    FETCH_COURSE_ASSIGNMENTS,
    CLEAR_COURSE_ASSIGNMENTS,
    FETCH_COURSE,
} from './courseConstants';

export function fetchCourses(course) {
    return async function (dispatch) {
        dispatch(asyncActionStart());
        try {
            dispatch({ type: FETCH_COURSES, payload: course });
            dispatch(asyncActionFinish());
        } catch (error) {
            dispatch(asyncActionError(error));
        }
    };
}

export function listenToSelectedCourse(course) {
    return {
        type: FETCH_COURSE,
        payload: course,
    };
}

export function fetchCourse(courseId) {
    return async function (dispatch) {
        dispatch(asyncActionStart());
        try {
            const snapshot = await fetchCourseFromFirestore(courseId).get();
            const course = dataFromSnapshot(snapshot);
            dispatch({ type: FETCH_COURSE, payload: course });
            dispatch(asyncActionFinish());
            return course;
        } catch (error) {
            dispatch(asyncActionError(error));
            toast.error(error);
        }
    };
}

export function fetchCourseAssignments(
    courseId,
    predicate,
    limit,
    lastDocSnapshot
) {
    return async function (dispatch) {
        dispatch(asyncActionStart());
        try {
            const snapshot = await fetchCourseAssignmentsFromUserFirestore(
                courseId,
                predicate,
                limit,
                lastDocSnapshot
            ).get();
            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            const moreAssignments = snapshot.docs.length >= limit;
            const assignments = snapshot.docs.map((doc) =>
                dataFromSnapshot(doc)
            );
            dispatch({
                type: FETCH_COURSE_ASSIGNMENTS,
                payload: { assignments, moreAssignments },
            });
            dispatch(asyncActionFinish());
            return lastVisible;
        } catch (error) {
            dispatch(asyncActionError(error));
            toast.error(error);
        }
    };
}

export function listenToCourses(courses) {
    return {
        type: FETCH_COURSES,
        payload: courses,
    };
}

export function createCourse(course) {
    return {
        type: CREATE_COURSE,
        payload: course,
    };
}

export function updateCourse(course) {
    return {
        type: UPDATE_COURSE,
        payload: course,
    };
}

export function deleteCourse(course) {
    return {
        type: DELETE_COURSE,
        payload: course,
    };
}

export function addAssignmentToCourse(courseId, assignment) {
    return {
        type: ADD_ASSIGNMENT_TO_COURSE,
        payload: { courseId, assignment },
    };
}

export function clearCourseAssignments() {
    return {
        type: CLEAR_COURSE_ASSIGNMENTS,
    };
}
