import { Form } from 'formik';
import React from 'react';
import styled from 'styled-components';

const StyledForm = styled(Form)`
  color: ${(p) => p.color};
  width: ${(p) => p.width || null};
  height: ${(p) => p.height || null};
  margin: ${p => p.margin || 'auto'}
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => <StyledForm {...props} />;
