import React from 'react';
import { useLocation } from 'react-router-dom';
import HomeFilledIcon from '../../app/icons/solid/Home';
import SearchFilledIcon from '../../app/icons/solid/Search';
import styled from 'styled-components';
import Avatar from '../../app/common/Avatar';
import StyledLink from '../../app/components/StyledLink';
import GridIconSolid from '../../app/icons/solid/ViewGrid';
import CollectionIconSolid from '../../app/icons/solid/Collection';
import { COLORS } from '../../app/layout/constants';

const NavWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0 0.5rem;
  list-style-type: none;
`;

const NavLinkContainer = styled.li`
  height: 100%;
  flex: 1 1 auto;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export default function SignedInLinksMobile({
  currentUser,
  currentUserProfile,
}) {
  const location = useLocation();

  let activeTab;

  let activeColor = COLORS.primaryText;
  let defaultColor = COLORS.tertiaryText;

  function setActiveTab() {
    switch (location.pathname) {
      case '/dash':
        activeTab = 0;
        break;
      case '/assignments':
        activeTab = 1;
        break;
      case '/courses':
        activeTab = 2;
        break;
      case '/course-search':
        activeTab = 3;
        break;
      case `/profile/${currentUser.uid}`:
        activeTab = 4;
        break;
      default:
        activeTab = null;
    }
  }
  setActiveTab();

  return (
    <NavWrapper>
      {/* to dashboard */}
      <NavLinkContainer>
        <StyledLink to="/dash">
          <HomeFilledIcon
            width="28px"
            color={activeTab === 0 ? activeColor : defaultColor}
          />
        </StyledLink>
      </NavLinkContainer>
      {/* to user assignments */}
      <NavLinkContainer>
        <StyledLink to="/assignments">
          <CollectionIconSolid
            width="28px"
            color={activeTab === 1 ? activeColor : defaultColor}
          />
        </StyledLink>
      </NavLinkContainer>
      {/* to user courses */}
      <NavLinkContainer>
        <StyledLink to="/courses">
          <GridIconSolid
            width="28px"
            color={activeTab === 2 ? activeColor : defaultColor}
          />
        </StyledLink>
      </NavLinkContainer>
      {/* to course search */}
      <NavLinkContainer>
        <StyledLink to="/course-search">
          <SearchFilledIcon
            width="28px"
            color={activeTab === 3 ? activeColor : defaultColor}
          />
        </StyledLink>
      </NavLinkContainer>
      {/* to user profile */}
      <NavLinkContainer>
        <StyledLink to={`/profile/${currentUser.uid}`}>
          <Avatar
            src={currentUserProfile?.photoURL}
            name={currentUser?.displayName}
            size="36px"
            small
            outline={activeTab === 4}
          />
        </StyledLink>
      </NavLinkContainer>
    </NavWrapper>
  );
}
