import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

export const AltLink = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.color ? p.color : p.theme.colors.secondaryText};
  font-weight: 600;
  a {
    color: ${(p) => p.theme.colors.secondaryText};
  }
`;
