import { toast } from 'react-toastify';
import { signOutFirebase } from '../firestore/authService';

export async function handleSignOut(history) {
    try {
        await signOutFirebase();
        history.push('/');
    } catch (error) {
        toast.error(error.message);
    }
}
