import React from 'react';
import { Main } from '../../app/components/Wrappers';
import Header from '../../app/common/Header';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { updateUserPassword } from '../../app/firestore/authService';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import StyledForm from '../../app/components/StyledForm';
import { ErrorMessage } from '../../app/components/Text';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

export default function ChangePassword() {
  const history = useHistory();
  return (
    <Main>
      <Header showBackBtn>Change Password</Header>
      <Formik
        initialValues={{ newPassword1: '', newPassword2: '' }}
        validationSchema={Yup.object({
          newPassword1: Yup.string().required('Password is required'),
          newPassword2: Yup.string().oneOf(
            [Yup.ref('newPassword1'), null],
            'Passwords do not match'
          ),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await updateUserPassword(values);
            toast.success('Password changed');
            history.push('/settings');
          } catch (error) {
            setErrors({ auth: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, isSubmitting, isValid, dirty, handleChange, values }) => (
          <StyledForm width="90%">
            <LoginTextInput
              width="100%"
              label="Enter New Password"
              name="newPassword1"
              onChange={handleChange}
              value={values.newPassword1}
              placeholder="New Password"
              type="password"
            />
            <LoginTextInput
              width="100%"
              label="Re-Enter Password"
              name="newPassword2"
              onChange={handleChange}
              value={values.newPassword2}
              placeholder="Password"
              type="password"
            />
            {errors.auth && <ErrorMessage>{errors.auth}</ErrorMessage>}
            <ButtonComponent
              type="submit"
              disabled={isSubmitting || !dirty || !isValid}
              width="100%"
              loading={isSubmitting}
              primary
              color="primary"
            >
              Change Password
            </ButtonComponent>
          </StyledForm>
        )}
      </Formik>
    </Main>
  );
}
