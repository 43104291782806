import React from 'react';
import { Fragment } from 'react';
import styled, { css } from 'styled-components';

const CourseMenu = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const activeTabStyles = css`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primaryText};
  border-bottom: 2px solid ${(p) => p.theme.colors.primaryText};
`;

const CourseMenuItem = styled.h5`
  flex: 1;
  font-weight: 500;
  height: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.theme.colors.tertiaryText};
  color: ${(p) => p.theme.colors.tertiaryText};
  ${(p) => p.activeTab && activeTabStyles}
`;

const MenuDivider = styled.div`
  height: 1rem;
  width: 1px;
  color: ${(p) => p.theme.colors.tertiaryText};
`;

export default function CourseDetailedMenu({
  activeTab,
  setActiveTab,
  setPredicate,
}) {
  function handleSetActiveTab(i, value) {
    setActiveTab(i);
    setPredicate('filter', value);
  }

  const panes = [
    { menuItem: 'Assignments', value: 'assignment' },
    { menuItem: 'Exams', value: 'exam' },
  ];

  return (
    <Fragment>
      <CourseMenu>
        {panes &&
          panes.map((pane, i) => {
            return (
              <CourseMenuItem
                activeTab={activeTab === i}
                onClick={() => handleSetActiveTab(i, pane.value)}
                key={i}
              >
                {pane.menuItem}
              </CourseMenuItem>
            );
          })}
        <MenuDivider />
      </CourseMenu>
    </Fragment>
  );
}
