import { toast } from 'react-toastify';
import {
    asyncActionError,
    asyncActionFinish,
    asyncActionStart,
} from '../../app/async/asyncReducer';
import {
    CLEAR_ASSIGNMENTS,
    CREATE_ASSIGNMENT,
    DELETE_ASSIGNMENT,
    FETCH_ASSIGNMENTS,
    LISTEN_TO_IMPORTANT_ASSIGNMENTS,
    LISTEN_TO_OVERDUE_ASSIGNMENTS,
    LISTEN_TO_SELECTED_ASSIGNMENT,
    LISTEN_TO_TODAYS_ASSIGNMENTS,
    LISTEN_TO_TOMORROWS_ASSIGNMENTS,
    UPDATE_ASSIGNMENT,
} from './assignmentConstants';
import { fetchUserAssignmentsFromFirestore } from '../../app/firestore/assignmentService';
import { dataFromSnapshot } from '../../app/firestore/firestoreService';

export function fetchAssignments(predicate, limit, lastDocSnapshot) {
    return async function (dispatch) {
        dispatch(asyncActionStart());
        try {
            const snapshot = await fetchUserAssignmentsFromFirestore(
                predicate,
                limit,
                lastDocSnapshot
            ).get();
            const lastVisible = snapshot.docs[snapshot.docs.length - 1];
            const moreAssignments = snapshot.docs.length >= limit;
            const assignments = snapshot.docs.map((doc) =>
                dataFromSnapshot(doc)
            );
            dispatch({
                type: FETCH_ASSIGNMENTS,
                payload: { assignments, moreAssignments },
            });
            dispatch(asyncActionFinish());
            return lastVisible;
        } catch (error) {
            dispatch(asyncActionError(error));
            toast.error(error);
        }
    };
}

export function createAssignment(assignment) {
    return {
        type: CREATE_ASSIGNMENT,
        payload: assignment,
    };
}

export function updateAssignment(assignment) {
    return {
        type: UPDATE_ASSIGNMENT,
        payload: assignment,
    };
}

export function deleteAssignment(assignmentId) {
    return {
        type: DELETE_ASSIGNMENT,
        payload: assignmentId,
    };
}

// export function listenToUserAssignments(assignments) {
//     return {
//         type: FETCH_ASSIGNMENTS,
//         payload: assignments,
//     };
// }

export function listenToTodaysAssignments(assignments) {
    return {
        type: LISTEN_TO_TODAYS_ASSIGNMENTS,
        payload: assignments,
    };
}

export function listenToTomorrowsAssignments(assignments) {
    return {
        type: LISTEN_TO_TOMORROWS_ASSIGNMENTS,
        payload: assignments,
    };
}

export function listenToOverdueAssignments(assignments) {
    return {
        type: LISTEN_TO_OVERDUE_ASSIGNMENTS,
        payload: assignments,
    };
}

export function listenToImportantAssignments(assignments) {
    return {
        type: LISTEN_TO_IMPORTANT_ASSIGNMENTS,
        payload: assignments,
    };
}

export function listenToSelectedAssignment(assignment) {
    return {
        type: LISTEN_TO_SELECTED_ASSIGNMENT,
        payload: assignment,
    };
}

export function clearAssignments() {
    return {
        type: CLEAR_ASSIGNMENTS,
    };
}
