import React from 'react';
import styled from 'styled-components';
import { DesktopLink } from '../../app/components/NavComponents';
import { SIZES } from '../../app/layout/constants';

const StyledSignedOutLinks = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-right: ${SIZES.lengthMd2};
`;
export default function SignedOutLinksDesktop() {
  return (
    <StyledSignedOutLinks>
      <DesktopLink to="/login">Login</DesktopLink>
      <DesktopLink to="/signup">Sign Up</DesktopLink>
    </StyledSignedOutLinks>
  );
}
