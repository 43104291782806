import * as React from 'react';

function SvgViewGrid(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.width || "24px"}
            width={props.width || "24px"}
            viewBox="0 0 20 20"
            fill={props.color}
            {...props}
        >
            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 8a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zm6-6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zm0 8a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg>
    );
}

export default SvgViewGrid;
