import React from 'react';
import ProfileHeader from './ProfileHeader';
import ProfileCourses from './ProfileCourses';
import Header from '../../app/common/Header';
import { Main } from '../../app/components/Wrappers';
import useFirestoreDoc from '../../app/hooks/useFirestoreDoc';
import { getUserProfile } from '../../app/firestore/profileService';
import { listenToSelectedUserProfile } from './profileActions';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../app/common/LoadingComponent';
import { Fragment } from 'react';
import { GLOBAL_MEDIA_QUERIES } from '../../app/layout/constants';
import Media from 'react-media';
import { PageTitle } from '../../app/components/Titles';

export default function UserProfile({ match }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { selectedUserProfile } = useSelector((state) => state.profile);
  const { loading, error } = useSelector((state) => state.async);
  const isCurrentUser = currentUser.uid === match.params.id;

  useFirestoreDoc({
    query: () => getUserProfile(match.params.id),
    data: (profile) => dispatch(listenToSelectedUserProfile(profile)),
    deps: [dispatch, match.params.id],
  });

  if ((loading && !selectedUserProfile) || (!selectedUserProfile && !error))
    return <LoadingComponent />;

  return (
    <Fragment>
      <Header
        showBackBtn={!isCurrentUser}
        icon={currentUser ? 'settings' : 'dots'}
        actionLink="/settings"
      >
        {selectedUserProfile?.displayName}
      </Header>
      <Main>
        <Media queries={GLOBAL_MEDIA_QUERIES}>
          {(matches) => (
            <Fragment>
              {(matches.medium || matches.large) && (
                <PageTitle>{selectedUserProfile.displayName}</PageTitle>
              )}
            </Fragment>
          )}
        </Media>
        <ProfileHeader
          isCurrentUser={isCurrentUser}
          profile={selectedUserProfile}
        />
        <ProfileCourses profile={selectedUserProfile} />
      </Main>
    </Fragment>
  );
}
