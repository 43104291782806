import { Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import Header from '../../app/common/Header';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import StyledForm from '../../app/components/StyledForm';
import { ErrorMessage } from '../../app/components/Text';
import { Main } from '../../app/components/Wrappers';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateUserEmail } from '../../app/firestore/authService';

export default function ChangeEmail() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const history = useHistory();
  return (
    <Main>
      <Header showBackBtn>Change Email</Header>
      <Formik
        initialValues={{ email: currentUserProfile?.email }}
        validationSchema={Yup.object({
          email: Yup.string().email().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await updateUserEmail(values);
            toast.success('Email changed');
            history.push('/settings');
          } catch (error) {
            setErrors({ auth: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, isSubmitting, isValid, dirty, handleChange, values }) => (
          <StyledForm width="90%">
            <LoginTextInput
              width="100%"
              label="Change Email"
              name="email"
              onChange={handleChange}
              value={values.email}
              placeholder="test@test.com"
              type="email"
            />
            {errors.auth && <ErrorMessage>{errors.auth}</ErrorMessage>}
            <ButtonComponent
              type="submit"
              disabled={isSubmitting || !dirty || !isValid}
              width="100%"
              loading={isSubmitting}
              primary
              color="primary"
            >
              Update Email
            </ButtonComponent>
          </StyledForm>
        )}
      </Formik>
    </Main>
  );
}
