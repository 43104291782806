import React, { Fragment } from 'react';
import DashboardListGroup from './DashboardListGroup';
import { Main } from '../../app/components/Wrappers';
import { useDispatch, useSelector } from 'react-redux';
import useFirestoreCollection from '../../app/hooks/useFirestoreCollection';
import Media from 'react-media';
import {
  listenToImportantAssignmentsFromFirestore,
  listenToOverdueAssignmentsFromFirestore,
  listenToTodaysAssignmentsFromFirestore,
  listenToTomorrowsAssignmentsFromFirestore,
} from '../../app/firestore/assignmentService';
import TopNav from '../nav/TopNav';
import {
  listenToImportantAssignments,
  listenToOverdueAssignments,
  listenToTodaysAssignments,
  listenToTomorrowsAssignments,
} from '../assignments/assignmentActions';
import { GLOBAL_MEDIA_QUERIES } from '../../app/layout/constants';
import { Message } from '../../app/components/Text';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import { MessageContainer } from '../../app/components/CourseComponents';
import { Redirect } from 'react-router';
import { AnimatePresence, motion } from 'framer-motion';
import LoadingSpinnerCentered from '../../app/components/LoadingSpinnerCentered';
import DashboardGreeting from './DashboardGreeting';

export default function Dashboard() {
  const dispatch = useDispatch();
  const {
    todaysAssignments,
    tomorrowsAssignments,
    overdueAssignments,
    importantAssignments,
  } = useSelector((state) => state.assignment);
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { authenticated } = useSelector((state) => state.auth);
  const { loading, error } = useSelector((state) => state.async);

  useFirestoreCollection({
    query: () => listenToTodaysAssignmentsFromFirestore(),
    data: (assignments) => dispatch(listenToTodaysAssignments(assignments)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => listenToTomorrowsAssignmentsFromFirestore(),
    data: (assignments) => dispatch(listenToTomorrowsAssignments(assignments)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => listenToOverdueAssignmentsFromFirestore(),
    data: (assignments) => dispatch(listenToOverdueAssignments(assignments)),
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () => listenToImportantAssignmentsFromFirestore(),
    data: (assignments) => dispatch(listenToImportantAssignments(assignments)),
    deps: [dispatch],
  });

  const containerVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      x: '-100vw',
      opacity: 0,
      transition: {
        ease: 'easeInOut',
      },
    },
  };

  if (!authenticated) return <Redirect to="/" />;
  if (error) return <Redirect to="/error" />;

  if (!error && !authenticated) return <LoadingSpinnerCentered />;

  return (
    <AnimatePresence>
      <motion.div
        variants={containerVariants}
        animate="visible"
        initial="hidden"
        exit="exit"
      >
        <Media queries={GLOBAL_MEDIA_QUERIES}>
          {(matches) => <Fragment>{matches.small && <TopNav />}</Fragment>}
        </Media>
        {loading ? (
          <LoadingSpinnerCentered />
        ) : (
          <Main>
            {currentUserProfile ? (
              <DashboardGreeting
                displayName={currentUserProfile?.displayName}
              />
            ) : null}
            {currentUserProfile?.courses.length === 0 &&
            currentUserProfile?.createdCourses === 0 ? (
              <MessageContainer>
                <Message>You aren't following any courses!</Message>
                <ButtonComponent primary color="primary" link="/course-search">
                  Find Yours
                </ButtonComponent>
              </MessageContainer>
            ) : null}
            <DashboardListGroup
              todaysAssignments={todaysAssignments}
              overdueAssignments={overdueAssignments}
              tomorrowsAssignments={tomorrowsAssignments}
              importantAssignments={importantAssignments}
            />
          </Main>
        )}
      </motion.div>
    </AnimatePresence>
  );
}
