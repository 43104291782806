import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../LoadingComponent';
import ModalWrapper from './ModalWrapper';
import {
  addAssignmentToCourseInFirestore,
  updateAssignmentInCourseInFirestore,
} from '../../firestore/courseService';
import { ButtonGroup } from '../../components/ButtonGroup';
import ButtonComponent from '../buttons/ButtonComponent';
import { closeModal, openModal } from './modalReducer';
import AssignmentFormInputs from '../../../features/create/AssignmentFormInputs';

const CourseForm = styled.form`
  width: 100%;
`;

export default function AssignmentFormModal({ course, selectedAssignment }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.async);

  let initialValues = {
    title: '',
    dueDay: new Date(),
    dueTime: '23:59',
    type: 'Homework',
    description: '',
  };

  if (selectedAssignment) {
    initialValues = {
      title: selectedAssignment.title,
      dueDay: selectedAssignment.dueDay,
      dueTime: selectedAssignment.dueTime,
      type: selectedAssignment.type,
      description: selectedAssignment.description,
    };
  }

  const validationSchema = Yup.object({
    title: Yup.string().required(),
    dueDay: Yup.string().required(),
    dueTime: Yup.string().required(),
    type: Yup.string().required(),
    description: Yup.string().notRequired(),
  });

  if (loading) return <LoadingComponent />;

  return (
    <ModalWrapper
      header={selectedAssignment ? 'Edit Assignment' : 'Add Assignment'}
      width="90vw"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            if (selectedAssignment) {
              await updateAssignmentInCourseInFirestore(
                course,
                values,
                selectedAssignment.id
              );
            } else {
              await addAssignmentToCourseInFirestore(course, values);
            }
          } catch (error) {
            toast.error(error.message);
          } finally {
            setSubmitting(false);
            dispatch(closeModal());
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          errors,
          touched,
        }) => (
          <CourseForm onSubmit={handleSubmit}>
            <AssignmentFormInputs
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
            <ButtonGroup style={{ width: '100%' }}>
              {selectedAssignment && (
                <ButtonComponent
                  color="error"
                  width="100%"
                  secondary
                  clickAction={() =>
                    dispatch(
                      openModal({
                        modalType: 'ConfirmAssignmentDelete',
                        modalProps: { assignment: selectedAssignment, history },
                      })
                    )
                  }
                >
                  Delete
                </ButtonComponent>
              )}
              <ButtonComponent
                style={{ marginTop: '3rem' }}
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                primary
                color="secondary"
                type="submit"
                width="100%"
              >
                {selectedAssignment ? 'Update' : 'Create'}
              </ButtonComponent>
            </ButtonGroup>
          </CourseForm>
        )}
      </Formik>
    </ModalWrapper>
  );
}
