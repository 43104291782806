import styled from 'styled-components';
import { SIZES } from '../layout/constants';
import React from 'react';

export const StyledBurger = styled.div`
  position: relative;
  width: ${SIZES.lengthMd3};
  height: ${SIZES.lengthSm1};
  background-color: ${(p) => p.theme.colors.primaryText};
  border-radius: ${SIZES.lengthSm1};

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background-color: inherit;
  }

  &::before {
    top: -${SIZES.lengthSm3};
  }
  &::after {
    top: ${SIZES.lengthSm3};
  }
`;

export const BurgerContainer = styled.div`
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  padding-right: ${SIZES.lengthMd3};
`;

export default function Burger({ action }) {
  return (
    <BurgerContainer onClick={action}>
      <StyledBurger />
    </BurgerContainer>
  );
}
