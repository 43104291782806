import styled, { keyframes } from 'styled-components';
import React from 'react';

const button_loading_spinner = keyframes`
    from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
`;

const LoadingSpinnerContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`;

const LoadingSpinner = styled.div`
  content: '';
  position: fixed;
  width: 16px;
  height: 16px;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: ${(p) => p.theme.colors.primary};
  border-radius: 50%;
  animation: ${button_loading_spinner} 1s ease infinite;
`;

export default function LoadingSpinnerCentered() {
  return (
    <LoadingSpinnerContainer>
      <LoadingSpinner />
    </LoadingSpinnerContainer>
  );
}
