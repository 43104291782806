import React from 'react';
import ButtonComponent from '../../../app/common/buttons/ButtonComponent';
import { format } from 'date-fns';
import {
  calculateScore,
  handleSetCompleted,
} from '../../../app/util/assignmentUtil';
import InfoComponent from '../../../app/common/InfoComponent';
import { Description } from '../../../app/components/CourseComponents';
import { Fragment } from 'react';
import { isAfter } from 'date-fns/esm';
import CircleButton from '../../../app/common/buttons/CircleButton';

export default function AssignmentDetailedHeader({ assignment }) {
  const now = new Date();

  return (
    <Fragment>
      <InfoComponent infoTitle="Course" info={assignment.course.title} />
      <InfoComponent
        infoTitle="Section"
        info={assignment.course.sectionTitle}
      />
      <InfoComponent infoTitle="Type" info={assignment.type} />
      <InfoComponent
        infoTitle="Due Date"
        info={format(assignment.dueDate, 'PPPP')}
      />
      <InfoComponent
        infoTitle="Due Time"
        info={format(assignment.dueDate, 'p')}
      />
      {assignment.completed ? (
        <ButtonComponent
          width="100%"
          secondary
          clickAction={() => handleSetCompleted(assignment)}
        >
          Mark Incomplete
        </ButtonComponent>
      ) : (
        <ButtonComponent
          clickAction={() => handleSetCompleted(assignment)}
          color="success"
          secondary
          width="100%"
          subtext={
            !assignment.completed && isAfter(assignment.dueDate, now) ? (
              <Fragment>
                Complete this assignment now and earn{' '}
                {calculateScore(assignment)} study points!
              </Fragment>
            ) : null
          }
        >
          Mark Complete
        </ButtonComponent>
      )}

      <Description>{assignment.description || 'No description'}</Description>
      <CircleButton
        secondary
        link={`/courses/${assignment.course.id}`}
        label="Go To Course"
      />
    </Fragment>
  );
}
