import React, { useState } from 'react';
import Card from '../../../app/components/Card';
import { COLORS } from '../../../app/layout/constants';
import styled from 'styled-components';
import Avatar from '../../../app/common/Avatar';
import {
  HeaderWrapper,
  HeaderTextWrapper,
} from '../../../app/components/Wrappers';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment } from 'react';
import { openModal } from '../../../app/common/modals/modalReducer';
import CircleButton from '../../../app/common/buttons/CircleButton';
import {
  handleUserFollowCourse,
  handleUserUnfollowCourse,
} from '../../../app/util/courseUtil';
import { format } from 'date-fns';
import { Badge } from '../../../app/components/Badge';
import { useHistory } from 'react-router';

const SearchItemContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  width: 100%;
  padding: 0rem 1rem;
  position: relative;
`;

const CourseTitle = styled.h3`
  font-size: 1.1rem;
  color: ${COLORS.primaryText};
  margin: 0;
`;

const SubTitle = styled.h4`
  color: ${COLORS.secondaryText};
  font-weight: 500;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`;

const CourseInfo = styled.h4`
  color: ${COLORS.secondaryText};
  font-weight: 500;
  margin: 0;
`;

const DateTime = styled.h4`
  color: ${COLORS.primaryText};
  margin: 0;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0;
`;

const BottomRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Description = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  max-height: 50px;
  width: 100%;
  margin-bottom: 1.75rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  left: 1rem;
  bottom: 0;
`;

export default function CourseSearchItem({ hit }) {
  const { currentUser } = useSelector((state) => state.auth);
  const isFollowing = hit.students.some((s) => s.id === currentUser.uid);
  const isAdmin = hit.adminUid === currentUser.uid;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const course = {
    ...hit,
    id: hit.objectID,
  };

  const {
    professorName,
    professorPhotoURL,
    meetingTime,
    description,
    objectID,
    sectionTitle,
    title,
    school,
    startDate,
    endDate,
  } = hit;

  const id = objectID;

  function handleFollow() {
    if (isFollowing) {
      handleUserUnfollowCourse(setLoading, id);
    } else {
      handleUserFollowCourse(setLoading, id, history);
    }
  }

  return (
    <Fragment>
      <Card bottomMargin="2rem">
        <SearchItemContainer>
          <HeaderWrapper>
            <Avatar name={professorName} src={professorPhotoURL} size="60px" />
            <HeaderTextWrapper>
              <CourseTitle>{sectionTitle}</CourseTitle>
              <ItemTextWrapper>
                {/* <SubTitle>{professorName}</SubTitle> */}
                <SubTitle>{title}</SubTitle>
                {/* <DateTime>{meetingTime}</DateTime> */}
              </ItemTextWrapper>
            </HeaderTextWrapper>
          </HeaderWrapper>
          <Row>
            <CourseInfo>
              {professorName} • {school}
            </CourseInfo>
          </Row>
          <Row>
            <CourseInfo>
              {format(startDate, 'LLLL do')} – {format(endDate, 'LLLL do')}
            </CourseInfo>
          </Row>
          <Row>
            <DateTime>{meetingTime}</DateTime>
          </Row>
          {description && (
            <Row>
              <Description>{description}</Description>
            </Row>
          )}
          <BottomRow>
            <ButtonGroup>
              {isAdmin ? (
                <CircleButton
                  icon="edit"
                  clickAction={() =>
                    dispatch(
                      openModal({
                        modalType: 'CourseOptionsModal',
                        modalProps: { course },
                      })
                    )
                  }
                  primary
                  color="black"
                >
                  Manage
                </CircleButton>
              ) : null}
              <CircleButton link={`/courses/${id}`} />
            </ButtonGroup>
          </BottomRow>
          {isAdmin ? <StyledBadge>Admin</StyledBadge> : null}
        </SearchItemContainer>
      </Card>
    </Fragment>
  );
}
