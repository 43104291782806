import React from 'react';
import { useDispatch } from 'react-redux';
import { ButtonGroup } from '../../components/ButtonGroup';
import { Message } from '../../components/Text';
import { deleteAssignmentFromCourseFirestore } from '../../firestore/courseService';
import ButtonComponent from '../buttons/ButtonComponent';
import { closeModal } from './modalReducer';
import ModalWrapper from './ModalWrapper';

export default function ConfirmAssignmentDeletionModal({
  assignment,
  history,
}) {
  const dispatch = useDispatch();

  function handleDeleteAssignment(courseId, assignmentId, history) {
    try {
      dispatch(closeModal());
      deleteAssignmentFromCourseFirestore(courseId, assignmentId);
      history.push(`/courses/${courseId}`);
    } catch (error) {
      throw error;
    }
  }

  return (
    <ModalWrapper header="Are you sure?">
      <Message>Are you sure you want to delete this assignment?</Message>
      <ButtonGroup>
        <ButtonComponent
          clickAction={() => dispatch(closeModal())}
          secondary
          style={{ marginRight: '1rem' }}
        >
          Cancel
        </ButtonComponent>
        <ButtonComponent
          style={{ marginLeft: '1rem' }}
          primary
          color="error"
          clickAction={() =>
            handleDeleteAssignment(assignment.course.id, assignment.id, history)
          }
        >
          Delete
        </ButtonComponent>
      </ButtonGroup>
    </ModalWrapper>
  );
}
