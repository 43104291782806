import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import SelectInput from '../../app/components/SelectInput';

const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 3rem;
`;

const ControlsTitle = styled.h4`
  color: ${(p) => p.theme.colors.secondaryText};
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  flex: 1 1 10rem;
`;

export default function AssignmentsPageControls({ predicate, setPredicate }) {
  function handleChange(e) {
    setPredicate('filter', e.target.value);
  }
  return (
    <Fragment>
      <ControlsWrapper>
        <ControlsTitle>Sort by</ControlsTitle>
        <SelectInput handleChange={handleChange} placeholder="Sort by:">
          <option
            selected={predicate.get('filter') === 'upcoming'}
            value="upcoming"
          >
            Upcoming
          </option>
          <option selected={predicate.get('filter') === 'exam'} value="exam">
            Exams
          </option>
          <option selected={predicate.get('filter') === 'quiz'} value="quiz">
            Quizzes
          </option>
          <option selected={predicate.get('filter') === 'lab'} value="lab">
            Labs
          </option>
          <option
            selected={predicate.get('filter') === 'overdue'}
            value="overdue"
          >
            Overdue
          </option>
          <option
            selected={predicate.get('filter') === 'completed'}
            value="completed"
          >
            Completed
          </option>

          <option
            selected={predicate.get('filter') === 'homework'}
            value="homework"
          >
            Homework
          </option>
          <option
            selected={predicate.get('filter') === 'project'}
            value="project"
          >
            Projects
          </option>
        </SelectInput>
      </ControlsWrapper>
    </Fragment>
  );
}
