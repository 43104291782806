import React from 'react';
import { useSelector } from 'react-redux';
import TestModal from './TestModal';
import ConfirmAssignmentDelete from './ConfirmAssignmentDeletionModal';
import ConfirmCourseDelete from './ConfirmCourseDeleteModal';
import AssignmentFormModal from './AssignmentFormModal';
import CourseOptionsModal from './CourseOptionsModal';
import CompletedAssignmentModal from './CompletedAssignmentModal';

export default function ModalManager() {
  const modalLookup = {
    TestModal,
    ConfirmAssignmentDelete,
    ConfirmCourseDelete,
    AssignmentFormModal,
    CourseOptionsModal,
    CompletedAssignmentModal,
  };
  const currentModal = useSelector((state) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
}
