import React from 'react';
import { useHistory } from 'react-router';
import Logo from '../../app/common/Logo';
import { TopNavContainer } from '../../app/components/NavComponents';

export default function TopNav() {
  const history = useHistory();
  return (
    <TopNavContainer>
      {/* <SettingsIcon /> */}
      <Logo onClick={() => history.push('/dash')}>StudyFlow</Logo>
      {/* <BellIcon /> */}
    </TopNavContainer>
  );
}
