import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from '../../components/Text';
import { COLORS } from '../../layout/constants';
import { InputWrapper, Label } from './LoginTextInput';

const Select = styled.select`
  border: none;
  padding: 1rem 0.5rem;
  background-color: ${COLORS.background};
  font-weight: 500;
  flex: 3;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(p) => p.theme.colors.primaryText};
  border: 1px solid ${(p) => p.theme.colors.tertiaryText};
  border-radius: 8px;
  width: ${(p) => p.width || '100%'};
  width: 100%;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${(p) => p.theme.colors.primaryText} 50%
    ),
    linear-gradient(
      135deg,
      ${(p) => p.theme.colors.primaryText} 50%,
      transparent 50%
    ),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.2em + 2px),
    calc(100% - 15px) calc(1.2em + 2px), calc(100% - 2.5em) 0.8em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  &:focus {
    outline: none;
    border: 3px solid ${(p) => p.theme.colors.primary};
    background-image: linear-gradient(
        45deg,
        ${(p) => p.theme.colors.primary} 50%,
        transparent 50%
      ),
      linear-gradient(
        135deg,
        transparent 50%,
        ${(p) => p.theme.colors.primary} 50%
      ),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1.2em, calc(100% - 20px) 1.2em,
      calc(100% - 2.5em) 0.8em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
  }
  &:active {
    outline: none;
  }
  &::placeholder {
    color: ${COLORS.secondaryText};
    font-weight: 400;
    font-size: 1rem;
  }
`;

export default function DropDownInput({
  label,
  placeholder,
  error,
  value,
  onChange,
  onBlur,
  name,
  type,
  options,
}) {
  return (
    <Fragment>
      <InputWrapper>
        {label && <Label>{label}</Label>}
        <Select
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          type={type}
        >
          {options &&
            options.map((option, i) => {
              return (
                <option key={i} value={option}>
                  {option}
                </option>
              );
            })}
        </Select>
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
}
