import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { BottomSection } from '../../app/components/BottomSection';
import { Message } from '../../app/components/Text';
import { CardHeading, PageTitle } from '../../app/components/Titles';
import { MainNoTop } from '../../app/components/Wrappers';
import { COLORS } from '../../app/layout/constants';
import { CourseGroup } from '../profile/ProfileCourses';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import CoursesList from './CoursesList';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  // exit: {
  //   x: '-100vw',
  //   opacity: 0,
  //   transition: {
  //     ease: 'easeInOut',
  //   },
  // },
};

export default function UserCourses() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const { courses, createdCourses } = currentUserProfile;

  return (
    <Fragment>
      <MainNoTop
        as={motion.main}
        variants={containerVariants}
        animate="visible"
        initial="hidden"
        exit="exit"
      >
        <PageTitle>My Courses</PageTitle>
        {courses.length > 0 && (
          <CourseGroup style={{ marginBottom: '2rem' }}>
            <CardHeading color={COLORS.primary}>Enrolled Courses</CardHeading>
            {!courses || courses.length < 1 ? (
              <Message>You aren't following any courses.</Message>
            ) : null}
            <motion.div
              variants={containerVariants}
              animate="visible"
              initial="hidden"
              exit="exit"
            >
              <CoursesList courses={courses} />
            </motion.div>
          </CourseGroup>
        )}
        <CourseGroup style={{ marginBottom: '6rem' }}>
          <CardHeading color={COLORS.primary}>Created Courses</CardHeading>
          {!createdCourses || createdCourses.length < 1 ? (
            <Message>You haven't created any courses!</Message>
          ) : null}
          <motion.div
            variants={containerVariants}
            animate="visible"
            initial="hidden"
            exit="exit"
          >
            <CoursesList courses={createdCourses} />
          </motion.div>
        </CourseGroup>
      </MainNoTop>
      <BottomSection>
        <ButtonComponent
          primary
          color="primary"
          width="100%"
          link="/create-course"
        >
          Create A Course
        </ButtonComponent>
      </BottomSection>
    </Fragment>
  );
}
