import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import BackIcon from '../../app/icons/solid/ArrowNarrowLeft';
import ActionIcon from '../../app/icons/solid/DotsHorizontal';
import SettingsIcon from '../../app/icons/outline/Cog';
import { COLORS, SHADOW } from '../layout/constants';
import StyledLink from '../components/StyledLink';

const HeaderWrapper = styled.header`
  z-index: 20;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  top: 0;
  left: 0;
  background: ${(p) => p.theme.colors.white};

  /* box-shadow: ${SHADOW.normal}; */
  border-bottom: 1px solid ${(p) => p.theme.colors.border};

  @media (min-width: 599px) {
    display: none;
  }
`;

const Title = styled.h1`
  font-size: 1rem;
  color: ${(p) => p.theme.colors.primaryText};
  flex-basis: 15rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default function Header({
  children,
  actionLink,
  showBackBtn,
  icon,
  action,
}) {
  const history = useHistory();

  let actionIcon;

  if (icon === 'settings') {
    actionIcon = <SettingsIcon />;
  } else {
    actionIcon = (
      <ActionIcon color={actionLink ? COLORS.primaryText : COLORS.background} />
    );
  }

  let renderedIcon = actionIcon;

  if (actionLink) {
    renderedIcon = <StyledLink to={actionLink}>{actionIcon}</StyledLink>;
  }

  if (action) {
    renderedIcon = <ActionIcon color={COLORS.primaryText} onClick={action} />;
  }

  return (
    <HeaderWrapper>
      <BackIcon
        onClick={showBackBtn ? history.goBack : null}
        color={showBackBtn ? COLORS.primaryText : COLORS.background}
      />
      <Title>{children}</Title>
      {renderedIcon}
    </HeaderWrapper>
  );
}
