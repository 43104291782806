import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

export const LogoFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 750px) {
    padding-left: 2rem;
    justify-content: flex-start;
    flex: 1;
    flex-basis: 10rem;
  }
`;

export const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
`;
export const LogoImg = styled.img`
  max-width: 2rem;
  flex: 1;
  margin-right: 0.25rem;
`;

export const LogoText = styled.h1`
  font-size: 1.35rem;
  font-weight: 700;
  flex: 1;
  color: ${(p) => p.theme.colors.primaryText};
`;

export default function Logo() {
  const history = useHistory();
  const { authenticated } = useSelector((state) => state.auth);
  return (
    <LogoFlex>
      <LogoContainer
        onClick={
          authenticated ? () => history.push('/dash') : () => history.push('/')
        }
      >
        <LogoImg src="/favicon_196.png" />
        <LogoText>StudyFlow</LogoText>
      </LogoContainer>
    </LogoFlex>
  );
}
