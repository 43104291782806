import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../../app/common/Header';
import LoadingComponent from '../../../app/common/LoadingComponent';
import { Main } from '../../../app/components/Wrappers';
import { listenToSelectedAssignmentFromFirestore } from '../../../app/firestore/assignmentService';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import { GLOBAL_MEDIA_QUERIES } from '../../../app/layout/constants';
import { listenToSelectedAssignment } from '../assignmentActions';
import { CLEAR_SELECTED_ASSIGNMENT } from '../assignmentConstants';
import AssignmentDetailedHeader from './AssignmentDetailedHeader';
import Media from 'react-media';
import { PageTitle } from '../../../app/components/Titles';
import { openModal } from '../../../app/common/modals/modalReducer';
import { BottomRightAction } from '../../../app/components/BottomRightAction';
import CircleButton from '../../../app/common/buttons/CircleButton';

export default function AssignmentDetailed({ match }) {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { selectedAssignment } = useSelector((state) => state.assignment);
  const { loading, error } = useSelector((state) => state.async);
  const isAdmin = currentUser?.uid === selectedAssignment?.course.adminUid;

  useFirestoreDoc({
    query: () => listenToSelectedAssignmentFromFirestore(match.params.id),
    data: (assignment) => dispatch(listenToSelectedAssignment(assignment)),
    deps: [dispatch, match.params.id],
  });

  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_SELECTED_ASSIGNMENT });
    };
  }, [dispatch]);

  if ((loading && !selectedAssignment) || (!selectedAssignment && !error))
    return <LoadingComponent />;

  return (
    <Fragment>
      <Media queries={GLOBAL_MEDIA_QUERIES}>
        {(matches) => (
          <Fragment>
            {matches.small && (
              <Header showBackBtn>{selectedAssignment.title}</Header>
            )}
          </Fragment>
        )}
      </Media>
      <Main height="calc(100vh - 9rem)">
        <Media queries={GLOBAL_MEDIA_QUERIES}>
          {(matches) => (
            <Fragment>
              {(matches.medium || matches.large) && (
                <PageTitle>{selectedAssignment.title}</PageTitle>
              )}
            </Fragment>
          )}
        </Media>
        <AssignmentDetailedHeader assignment={selectedAssignment} />
        {isAdmin && (
          <BottomRightAction>
            <CircleButton
              color="secondary"
              icon="pencil"
              size="3rem"
              clickAction={() =>
                dispatch(
                  openModal({
                    modalType: 'AssignmentFormModal',
                    modalProps: {
                      course: selectedAssignment.course,
                      selectedAssignment,
                    },
                  })
                )
              }
            />
          </BottomRightAction>
        )}
      </Main>
    </Fragment>
  );
}
