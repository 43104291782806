import { Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import Header from '../../app/common/Header';
import LoginTextInput from '../../app/common/inputs/LoginTextInput';
import StyledForm from '../../app/components/StyledForm';
import { ErrorMessage } from '../../app/components/Text';
import { Main } from '../../app/components/Wrappers';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { updateUserDisplayName } from '../../app/firestore/profileService';

export default function ChangeName() {
  const { currentUserProfile } = useSelector((state) => state.profile);
  const history = useHistory();
  return (
    <Main>
      <Header showBackBtn>Change Name</Header>
      <Formik
        initialValues={{ name: currentUserProfile?.displayName }}
        validationSchema={Yup.object({
          name: Yup.string().required(),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await updateUserDisplayName(values);
            toast.success('Name changed');
            history.push('/settings');
          } catch (error) {
            setErrors({ auth: error.message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, isSubmitting, isValid, dirty, handleChange, values }) => (
          <StyledForm width="90%">
            <LoginTextInput
              width="100%"
              label="Change Name"
              name="name"
              onChange={handleChange}
              value={values.name}
              placeholder="John Doe"
              type="text"
            />
            {errors.auth && <ErrorMessage>{errors.auth}</ErrorMessage>}
            <ButtonComponent
              type="submit"
              disabled={isSubmitting || !dirty || !isValid}
              width="100%"
              loading={isSubmitting}
              primary
              color="primary"
            >
              Update Name
            </ButtonComponent>
          </StyledForm>
        )}
      </Formik>
    </Main>
  );
}
