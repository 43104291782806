import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import ButtonComponent from '../../app/common/buttons/ButtonComponent';
import { PageTitleCenter } from '../../app/components/Titles';
import { MainNoTop } from '../../app/components/Wrappers';
// import { googleLogin } from '../../app/firestore/authService';

const WelcomeWrapper = styled.div`
  height: 90vh;
  /* position: aboslute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WelcomeContent = styled.div`
  width: 100%;
`;

export default function WelcomePage({ history }) {
  const { authenticated } = useSelector((state) => state.auth);

  if (authenticated) return <Redirect to="/dash" />;
  return (
    <MainNoTop>
      <WelcomeWrapper>
        <WelcomeContent>
          <PageTitleCenter>Welcome</PageTitleCenter>
          <ButtonComponent width="20rem" primary color="primary" link="/signup">
            Sign Up
          </ButtonComponent>
          <ButtonComponent
            width="20rem"
            secondary
            color="primary"
            link="/login"
          >
            Login
          </ButtonComponent>
          {/* <ButtonComponent
            width="100%"
            primary
            color="google"
            clickAction={googleLogin}
          >
            Sign in with Google
          </ButtonComponent> */}
        </WelcomeContent>
      </WelcomeWrapper>
    </MainNoTop>
  );
}
